import { Component, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizeService } from '../../../../api-authorization/authorize.service';
import { ApiService, ActionResult } from '../../../api.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AccountService } from '../../../modules/account/account-service';
import { NotificationService } from '../../services/notification.service';
import { LogService } from '../../services/log.service';
import { AppSettingsService } from '../../services/appsettings.service';
import { Console } from 'console';

@Component({
  selector: 'app-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss']
})
/** account-selector component*/
export class AccountSelectorComponent {
  /** account-selector ctor */
  public isAuthenticated: Observable<boolean>;
  private apiService: AccountService;
  public accountList: AccountListQueryResult[];
  public cycleAccess: boolean = false;
  public telecomAccountList: AccountListQueryResult[];
  public metroAccountList: AccountListQueryResult[];
  public currentAccount: AccountListQueryResult;
  public cycleSearchList: CycleAccountListItem[];
  private router: Router;
  private _location: Location;
  private notificationService: NotificationService;
  private searchTimer: any;
  private searchTimerDuration = 600;
  public searchQueryString: string;
  public searchIsDirty: boolean = false;
  public searchInProgress: boolean = false;
  private isChangingPage: boolean = false;

  constructor(
    private _apiService: AccountService,
    private authorizeService: AuthorizeService,
    private _router: Router,
    private __location: Location,
    private _notificationService: NotificationService,
    private logService: LogService,
    private appSettingsService: AppSettingsService) {
    this.apiService = _apiService;
    this.router = _router;
    this._location = __location;
    this.notificationService = _notificationService;
  }

  loadAccountList() {
    const accountListQueryPost: AccountListQuery = {};
    //Temp store selected account
    var currentAccountKey = localStorage.getItem("AccountKey");

    //Clear localstorage
    this.logService.info("GetAccountList", "Start");
    this.apiService.GetAccountList(accountListQueryPost).subscribe(res => {

      console.warn("GET ACCOUNT LIST");

      this.logService.debug("GetAccountList", accountListQueryPost, res);
      this.accountList = res.Result.Accounts;
      this.cycleAccess = res.Result.CycleAccess;
      this.telecomAccountList = this.accountList.filter(x => x.AccountType == "T");
      this.metroAccountList = this.accountList.filter(x => x.AccountType == "S");

      var matchingAccount = this.accountList.find(x => x.AccountKey == currentAccountKey);

      if (!matchingAccount || this.isAccountInStorageNull()) {
        this.currentAccount = this.accountList[0];
        console.log("Location 1");
        localStorage.setItem("AccountNumber", this.accountList[0].AccountNumber);
        localStorage.setItem("AccountAddress", this.accountList[0].Address);
        localStorage.setItem("AccountType", this.accountList[0].AccountType);
        localStorage.setItem("AccountKey", this.accountList[0].AccountKey);
        this.printAccountInStorage("NonMatch");
        this.changeAccount(this.accountList[0].AccountNumber);

      } else {
        this.currentAccount = matchingAccount;
        console.log("Location 2");
        localStorage.setItem("AccountNumber", this.currentAccount.AccountNumber);
        localStorage.setItem("AccountAddress", this.currentAccount.Address);
        localStorage.setItem("AccountType", this.currentAccount.AccountType);
        localStorage.setItem("AccountKey", this.currentAccount.AccountKey);
        this.printAccountInStorage("Match");
        this.updateNotices();
      }
      
      //TODO: Change the theme based on account type

    }, error => {
      this.logService.error("GetAccountList", error, accountListQueryPost);
    });
  }

  printAccountInStorage(prefix: string) {
    console.warn(prefix + ": AccountNumber: " + localStorage.getItem("AccountNumber"));
    console.warn(prefix + ": AccountAddress: " + localStorage.getItem("AccountAddress"));
    console.warn(prefix + ": AccountType: " + localStorage.getItem("AccountType"));
    console.warn(prefix + ": AccountKey: " + localStorage.getItem("AccountKey"));
  }

  isAccountInStorageNull() {
    var accountKey = localStorage.getItem("AccountKey");
    if (accountKey == null || "null".match(accountKey)) {
      return true;
    }
    var accountNumber = localStorage.getItem("AccountNumber");
    if (accountNumber == null || "null".match(accountNumber)) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.loadAccountList();
  }

  changeAccount(accountNumber: string) {
    this.currentAccount = this.accountList.find(x => x.AccountNumber == accountNumber);
    console.log("Location 3");
    localStorage.setItem("AccountNumber", this.currentAccount.AccountNumber);
    localStorage.setItem("AccountAddress", this.currentAccount.Address);
    localStorage.setItem("AccountType", this.currentAccount.AccountType);
    localStorage.setItem("AccountKey", this.currentAccount.AccountKey);

    //TODO: Change the theme based on account type
    if (this.router.navigated === false) {
      // Case when route was not used yet
      this.router.navigateByUrl('/profile/dashboard');
    } else {
      this.router.navigateByUrl("/profile/overview").then(
        () => {
          this.router.navigateByUrl('/profile/dashboard');
        });
    }

    this.updateNotices();
    this.logService.debug("ChangeAccount", accountNumber, this.currentAccount);
  }

  changeToCycleAccount(accountNumber: string) {
    this.currentAccount = this.cycleSearchList.find(x => x.AccountNumber == accountNumber);
    console.log("Location 4");
    localStorage.setItem("AccountNumber", this.currentAccount.AccountNumber);
    localStorage.setItem("AccountAddress", this.currentAccount.Address);
    localStorage.setItem("AccountType", this.currentAccount.AccountType);
    localStorage.setItem("AccountKey", this.currentAccount.AccountKey);

    if (this.router.navigated === false) {
      // Case when route was not used yet
      this.router.navigateByUrl('/profile/dashboard');
    } else {
      this.router.navigateByUrl("/profile/overview").then(
        () => {
          this.router.navigateByUrl('/profile/dashboard');
        });
    }

    this.updateNotices();
    this.logService.debug("ChangeAccount", accountNumber, this.currentAccount);
  }

  updateNotices() {
    var accountNoticeQuery: AccountNoticesQuery = { CustomerId: this.currentAccount.AccountKey };
    this.apiService.GetNotices(accountNoticeQuery).subscribe(res => {

      if (res.Result.Success) {
        var noticeService = this.notificationService;
        noticeService.clear();
        res.Result.Notices.forEach(function (item) {
          if (item.State.toLowerCase() == "info") {
            noticeService.info(item.Message);
          }
          else if (item.State.toLowerCase() == "warning") {
            noticeService.warn(item.Message);
          }
          else if (item.State.toLowerCase() == "success") {
            noticeService.success(item.Message);
          }
          else if (item.State.toLowerCase() == "danger") {
            noticeService.error(item.Message);
          }
          else {
            noticeService.info(item.Message);
          }
        });

      }

      this.updateSiteMessage();
    }, error => console.error(error));
  }

  updateSiteMessage() {
    // display notification from settings
    if (this.appSettingsService.settings.siteMessage?.length > 0) {

      if (this.appSettingsService.settings.siteMessageExpiration != -1) {
        var now = (Date.now() / 1000);
        if (now > this.appSettingsService.settings.siteMessageExpiration) {
          return;
        }
      }

      var message = this.appSettingsService.settings.siteMessage;
      var style = this.appSettingsService.settings.siteMessageStyle ?? "info";
      if (style == "warn") {
        this.notificationService.warn(message);
      }
      else if (style == "error") {
        this.notificationService.error(message);
      }
      else if (style == "success") {
        this.notificationService.success(message);
      }
      else {
        this.notificationService.info(message);
      }
    }
  }

  searchCycleAccounts(value: string) {
    if (value.length == 0) {
      this.cycleSearchList = null;
      this.searchQueryString = "";
      return;
    }

    if (value.length > 0 && value.length < 4)
      return;

    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.initiateCycleSearch(value);
    }, this.searchTimerDuration);
  }


  initiateCycleSearch(value) {
    if (!this.isChangingPage) {
      this.isChangingPage = true;
      this.searchInProgress = true;

      var searchModel: CycleSearchAccountQuery = {
        Search: value
      };
      this.apiService.GetCycleSearch(searchModel).subscribe(data =>
      {
        if (data && data.Result && data.Result.Success) {
          this.cycleSearchList = data.Result.Accounts;
        } else {
          this.cycleSearchList = null;
        }
        this.isChangingPage = false;
        this.searchInProgress = false;
        this.searchIsDirty = true;
        this.searchQueryString = value;
      }, error => {
          this.isChangingPage = false;
          this.searchInProgress = false;
          this.searchIsDirty = true;
          this.searchQueryString = value;
      });
    }
  }

}

export interface AccountListQuery {

}

export interface AccountListQueryResult {
  AccountNumber: string,
  AccountType: string,
  FirstName: string,
  LastName: string,
  Address: string,
  AccountKey: string
}

export interface ActionListQueryResults extends ActionResult {
  Accounts: AccountListQueryResult[],
  CycleAccess: boolean
}

export interface AccountNoticesQuery {
  CustomerId: string
}

export interface AccountNoticesQueryResult extends ActionResult {
  AccountNumber: string
  Notices: AccountNoticeItem[]
}

export interface AccountNoticeItem {
  State: string,
  Message: string,
  Action: string,
  MessageDate: Date
}

export interface CycleSearchAccountQuery {
  Search: string
}

export interface CycleAccountListItem {
  AccountNumber: string,
  AccountType: string,
  Address: string,
  FirstName: string,
  LastName: string,
  Active: boolean,
  AccountKey: string
}

export interface CycleSearchAccountQueryResult extends ActionResult {
  Accounts: CycleAccountListItem[]
}

<router-outlet></router-outlet>

<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-child-name"
     [config]="{backdrop: 'static',  keyboard: false}"
     id="timeoutmodal"
     >
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="m-0 modal-title">Are you there?</h4>
      </div>
      <div class="modal-body text-center">
        {{idleState}}
        <div class="row mrgbtm">
          <div class="col-md-6">
            <button type="button" (click)="logout()" class="btn btn-danger">Logout</button>
          </div>
          <div class="col-md-6">
            <button type="button" (click)="resetTimeout()" class="btn btn-primary">Stay</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

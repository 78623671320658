import { Component, ViewChild, ElementRef, Input, OnInit, Output, EventEmitter, HostListener, Renderer2 } from '@angular/core';
import { ApiService, ActionResult } from '../../../../api.service';
import { CurrencyPipe } from '@angular/common';
import { TitleService } from '../../../../core/services/title.service';
import { PaymentService } from '../../payment.service';
import { ChangeDetectorRef } from '@angular/core';
import { Toast, ToastrService } from 'ngx-toastr';
import _resourcedata from '../../../../../assets/resources/resource.json';
import swal from 'sweetalert2';
import { LogService } from '../../../../core/services/log.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-paynow',
  templateUrl: './paynow.component.html',
  styleUrls: ['./paynow.component.scss']
})
/** paynow component*/
export class PaynowComponent implements OnInit {
  @ViewChild('customAmountInput') public customAmountInput: ElementRef;
  @ViewChild('redirectPostForm', { static: false }) public redirectPostForm: ElementRef;
  @ViewChild('submitButton') public submitButton: ElementRef;

//  @ViewChild('spreedlyNameInput') public spreedlyNameInput: ElementRef;
//  @ViewChild('spreedlyMonthInput') public spreedly MonthInput: ElementRef;
//  @ViewChild('spreedlyYearInput') public spreedlyYearInput: ElementRef;
  @ViewChild('spreedlyPayNowSubmitButton') public spreedlyPayNowSubmitButton: ElementRef;
  @ViewChild('spreedlyAutoPaySubmitButton') public spreedlyAutoPaySubmitButton: ElementRef;

  @Input() paymentRadio: string;

  // Spreedly Data
  public spreedlyLoaded: boolean;

  public spreedlyPayNowInputName: string;
  public spreedlyPayNowInputMonth: string;
  public spreedlyPayNowInputYear: string;

  public spreedlyAutoPayInputName: string;
  public spreedlyAutoPayInputMonth: string;
  public spreedlyAutoPayInputYear: string;

  // Loaded Data
  public payNowInfoQueryResult: PayNowInfoQueryResult;
  public autoPayInfoResult: AutoPayInfoResult;

  // Values
  private resource: object;
  private amountToPay: number;
  private hasCardOnFile: boolean = false;
  public token: string;
  public extraData: { [key: string]: string };
  private redirectUrl: string;
  public formattedAmount: string;
  private encryptedParam: string;

  // Load States
  public loaded: boolean = false;
  private isPayNowInfoLoaded: boolean = false;
  private isAutoPayInfoLoaded: boolean = false;
  private loadAuthorizeNet: Promise<any>;

  // Switches
  private disableCustomAmount: boolean = false;
  private disableContinueButton: boolean = false;
  private disableContinueButtonIsAutopay: boolean = false;
  public showVirtualTerminal: boolean = false;


  public showAuthorizeNetProd: boolean = false;
  public showAuthorizeNetTest: boolean = false;

  public showPayNowInfo: boolean = true;
  public showAuthorizeNetPayNowIframe: boolean = false;
  public showSpreedlyPayNowIframe: boolean = false;

  public showAutoPayInfo: boolean = true;
  public showAuthorizeNetAutoPayIframe: boolean = false;
  public authorizeNetProfileAction = "customer/addPayment";
  public showSpreedlyAutoPayIframe: boolean = false;


  /** paynow ctor */
  constructor(private paymentService: PaymentService,
    protected titleService: TitleService,
    protected currencyPipe: CurrencyPipe,
    protected ref: ChangeDetectorRef,
    protected toastrService: ToastrService,
    protected logService: LogService,
    private router: Router,
    private renderer: Renderer2) {
    this.titleService.setTitle("Make A Payment");
    this.resource = _resourcedata.PayNow;

    this.loadPayNowInfo();
    this.loadAutoPayInfo();
  }

  ngOnInit() {

  }

  // Initial Load PayNow Information
  loadPayNowInfo() {
    const payNowInfoQuery: PayNowInfoQuery = { CustomerId: localStorage.getItem("AccountKey") };
    this.paymentService.GetPayNowInfo(payNowInfoQuery).subscribe(res => {
      this.payNowInfoQueryResult = res.Result;
      this.paymentRadio = PaymentRadio.Current;
      this.disableCustomAmount = true;
      this.isPayNowInfoLoaded = true;
      this.checkInitialLoadState();
    }, error => console.error(error));
  }

  // Initial Load AutoPay Information
  loadAutoPayInfo() {
    const autoPayInfoQuery: AutoPayInfoQuery = { CustomerId: localStorage.getItem("AccountKey") };
    this.paymentService.GetAutoPayInfo(autoPayInfoQuery).subscribe(res => {
      this.hasCardOnFile = res.Result.CardOnFile;
      this.autoPayInfoResult = res.Result;
      this.isAutoPayInfoLoaded = true;
      this.checkInitialLoadState();
    }, error => console.error(error));
  }

  // Check initial load state
  checkInitialLoadState() {
    if (this.isAutoPayInfoLoaded && this.isPayNowInfoLoaded) {
      this.loaded = true;
    }
  }

  // Continue To Pay
  public continueToPay() {
    if (this.paymentRadio == PaymentRadio.Current) {
      this.amountToPay = this.payNowInfoQueryResult.CurrentBalance;
    } else {
      this.amountToPay = +this.customAmountInput.nativeElement.value.replace("$", "").replace(",", "");
    }
    if (isNaN(this.amountToPay)) {
      this.disableContinueButton = false;
      this.toastrService.error("Invalid number entered.", null, { positionClass: 'toast-bottom-center' });
      return;
    }
    if (this.amountToPay > this.payNowInfoQueryResult.CurrentBalance) {
      swal.fire({
        title: 'Confirm',
        text: this.resource["AmountOverBalance"],
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          this.continueToPayStep2();
        }
      })

      return;
    }
    if (this.amountToPay <= 0) {
      this.toastrService.error("Amount to pay must be greater than 0 and less than or equal to current balance.", null, { positionClass: 'toast-bottom-center' });
      return;
    }

    this.continueToPayStep2();
  }

  // Continue to Pay Step 2
  private continueToPayStep2() {
    this.disableContinueButton = true;

    const payNowCommandRequest: PayNowCommandRequest = {
      CustomerId: localStorage.getItem("AccountKey"),
      CurrentBalance: this.paymentRadio == PaymentRadio.Current,
      Amount: this.amountToPay
    };

    this.logService.info("PayNowCommand", "Start");
    this.paymentService.PostPayNow(payNowCommandRequest).subscribe(res => {
      this.logService.debug("PayNowCommand", payNowCommandRequest, res);
      if (!res.Result.Success) {
        swal.fire(res.Result.Message);
        this.disableContinueButton = false;
      } else {
        this.token = res.Result.Token;
        this.redirectUrl = res.Result.RedirectUrl;
        this.encryptedParam = res.Result.EncryptedParam;


        this.payNowInfoQueryResult.GatewayType = "virtualterminal";

        switch (this.payNowInfoQueryResult.GatewayType) {
          case "authorizenet":
          case "sandboxauthorizenet":
            {
              this.disableContinueButton = false;
              const isTest = "authorizenet".match(this.payNowInfoQueryResult.GatewayType) ? false : true;
              this.initAuthorizeNet(isTest, true);
              setTimeout(function () {
                $("#send_paymenttoken").submit();
              }, 1000);
              //$(".AcceptUI").click();
              break;
            }
          case "virtualterminal":
            console.log("token is " + this.token);
            console.log("redirect url is  " + this.redirectUrl);
            $("#PayaTokenizedXml").val(this.token.toString());
            $("#RedirectUrl").val(this.redirectUrl.toString());
            this.showVirtualTerminal = true;
            this.redirectPostForm.nativeElement.submit();
            break;
          case "spreedly":
            {
              this.disableContinueButton = false;
              this.initSpreedly(true);
            }
            break;
        }
      }
    }, error => {
      this.logService.error("PayNowCommand", error, payNowCommandRequest);
    });
  }

  //Formats the number input into a currency with $ prepended and limit to 2 decimal numbers.
  transformAmount(element) {
    this.formattedAmount = this.currencyPipe.transform(this.formattedAmount, '$');
    element.target.value = this.formattedAmount;
  }

  /* Spreedly */
  initSpreedly(isPayment: boolean) {
    this.loadSpreedlyScript(isPayment);

    this.showPayNowInfo = false;
    this.showAutoPayInfo = false;

    if (isPayment) {
      this.showSpreedlyPayNowIframe = true;
    }
    else {
      this.showSpreedlyAutoPayIframe = true;
    }
  }

  loadSpreedlyScript(isPayment: boolean) {
    if (this.spreedlyLoaded) {
      this.loadSpreedlyIframe(isPayment);
    }
    else {
      const script = this.renderer.createElement('script');
      script.src = "https://core.spreedly.com/iframe/iframe-v1.min.js";
      script.onload = () => {
        this.spreedlyLoaded = true;
        this.loadSpreedlyIframe(isPayment);
      };
      this.renderer.appendChild(document.head, script);
    }
  }

  loadSpreedlyIframe(isPayment: boolean) {
    if (isPayment) {
      this.loadSpreedlyPaynowIframe();
    }
    else {
      this.loadSpreedlyAutoPayIframe();
    }
  }

  loadSpreedlyPaynowIframe() {

    var token = "8tLoZdsqY0O2pv2bdmsJY5gZ5om";
    var tokenCallback = this.onReceiveSpreedlyTokenizedPaymentMethod;
    var encryptedParam = this.encryptedParam;
    var paymentService = this.paymentService;
    var router = this.router;
    var payNowSubmitButton = this.spreedlyPayNowSubmitButton.nativeElement;

    // @ts-ignore
    var payNowFrame = new SpreedlyPaymentFrame();
    // @ts-ignore
    payNowFrame.init(token, {
      "numberEl": "spreedly-paynow-number",
      "cvvEl": "spreedly-paynow-cvv"
    });
    // @ts-ignore
    payNowFrame.on("ready", function () {
      //var submitButton = document.getElementById('submit-button');
      //submitButton.disabled = false;
      payNowSubmitButton.disabled = true;
      // @ts-ignore
      console.log("change detected in spreedly input");
      payNowFrame.setLabel("number", "Card Number");
      // @ts-ignore
      payNowFrame.setLabel("cvv", "CVV");
    });

    // @ts-ignore
    payNowFrame.on('paymentMethod', function (token, pmData) {
      console.log("payment method triggered");
      tokenCallback(token, pmData, encryptedParam, paymentService, router);
    });
  }

  loadSpreedlyAutoPayIframe() {

    var token = "8tLoZdsqY0O2pv2bdmsJY5gZ5om";
    var tokenCallback = this.onReceiveSpreedlyTokenizedPaymentMethod;
    var encryptedParam = this.encryptedParam;
    var paymentService = this.paymentService;
    var router = this.router;
    var autoPaySubmitButton = this.spreedlyPayNowSubmitButton.nativeElement;

    // @ts-ignore
    var autoPayFrame = new SpreedlyPaymentFrame();
    // @ts-ignore
    autoPayFrame.init(token, {
      "numberEl": "spreedly-autopay-number",
      "cvvEl": "spreedly-autopay-cvv"
    });
    // @ts-ignore
    autoPayFrame.on("ready", function () {
      //var submitButton = document.getElementById('submit-button');
      //submitButton.disabled = false;
      autoPaySubmitButton.disabled = false;
      // @ts-ignore
      autoPayFrame.setLabel("number", "Card Number");
      // @ts-ignore
      autoPayFrame.setLabel("cvv", "CVV");
    });
    // @ts-ignore
    autoPayFrame.on('paymentMethod', function (token, pmData) {
      tokenCallback(token, pmData, encryptedParam, paymentService, router);
    });
  }

  submitPayNowSpreedly() {
    var requiredFields = {};

    // Get required, non-sensitive, values from host page
    requiredFields["full_name"] = this.spreedlyPayNowInputName;
    requiredFields["month"] = this.spreedlyPayNowInputMonth;
    requiredFields["year"] = this.spreedlyPayNowInputYear;

    console.info("Required Fields: " + JSON.stringify(requiredFields));

    // @ts-ignore
    Spreedly.tokenizeCreditCard(requiredFields, function(errors) {
      if (errors && errors.length > 0) {
        // Handle errors
        for (var i = 0; i < errors.length; i++) {
          var error = errors[i];
          console.log(error);
        }
      } else {
        // Tokenization successful
        console.log("Credit card tokenization successful");
      }
    });
  }

  onReceiveSpreedlyTokenizedPaymentMethod(token, pmData, encryptedParam, paymentService, router) {
    console.info("Token: " + token);
    console.info("PM Data: " + JSON.stringify(pmData));
    console.info("Encrypted Param: " + JSON.stringify(encryptedParam));

    // Handles transact resposne
    const payNowCommandRequest: PayNowCallbackCommandRequest = {
      EncryptedResponse: token,
      EncryptedParam: encryptedParam
    };
    paymentService.PostPayNowCallback(payNowCommandRequest).subscribe(res => {
      if (res?.Result?.Success) {
        router.navigateByUrl('/pay/now-callback?response=success');
      }
      else {
        router.navigateByUrl('/pay/now-callback?response=error');
      }
    }, error => console.error(error));
  }

  cancelSpreedlyPayNow() {
    this.cancelSpreedly();
  }

  cancelSpreedlyAutoNow() {
    this.cancelSpreedly();
  }

  cancelSpreedly() {
    this.showPayNowInfo = true;
    this.showAutoPayInfo = true;
    this.showSpreedlyPayNowIframe = false;
    this.showSpreedlyAutoPayIframe = false;
  }


  /* ANet */

  @HostListener('window:anet.resize', ['$event.detail'])
  handleAnetResize(data) {
    console.log('hostlistener resize');
    console.log(data);
    setTimeout(function () {
      console.log('h: ' + data.height);
      $("#load_payment").attr("height", data.height);
      $("#load_payment").css("height", data.height + "px");
      console.log('height adjusted');
    }, 100);
  }

  @HostListener('window:anet.cancel', ['$event.detail'])
  handleAnetCancel(data) {
    this.cancelAuthorizeNet();
  }

  @HostListener('window:anet.save', ['$event.detail'])
  handleAnetSave(data) {
    const autoPayCallbackRequest: AutoPayCallbackCommandRequest = {
      EncryptedResponse: 'save', //There is no data that returns from ANET on the payment profile
      EncryptedParam: this.encryptedParam
    };
    this.paymentService.PostAutoPayCallback(autoPayCallbackRequest).subscribe(res => {
      if (res?.Result?.Success) {
        this.router.navigateByUrl('/pay/autopay-callback?response=success');
      }
      else {
        this.router.navigateByUrl('/pay/autopay-callback?response=error');
      }
    }, error => console.error(error));
  }

  @HostListener('window:anet.transact', ['$event.detail'])
  handleAnetTransact(data) {
    // Handles transact resposne
    const payNowCommandRequest: PayNowCallbackCommandRequest = {
      EncryptedResponse: data.response,
      EncryptedParam: this.encryptedParam
    };
    this.paymentService.PostPayNowCallback(payNowCommandRequest).subscribe(res => {
      if (res?.Result?.Success) {
        this.router.navigateByUrl('/pay/now-callback?response=success');
      }
      else {
        this.router.navigateByUrl('/pay/now-callback?response=error');
      }
    }, error => console.error(error));
    //sessionStorage.removeItem("HPTokenTime");
  }

  initAuthorizeNet(isTest: boolean, isPayment: boolean) {
    this.loadAuthorizeNet = new Promise((resolve) => {
      //this.loadAuthorizeNetScript(isTest);

      // @ts-ignore
      window.CommunicationHandler = {};

      // @ts-ignore
      window.CommunicationHandler.onReceiveCommunication = function (argument) {

        function parseQueryString(str) {
          var vars = [];
          var arr = str.split('&');
          var pair;
          for (var i = 0; i < arr.length; i++) {
            pair = arr[i].split('=');
            vars[pair[0]] = unescape(pair[1]);
          }
          return vars;
        }

        console.log('communication handler enter');
        console.log(JSON.stringify(argument));
        var params = parseQueryString(argument.qstr)
        switch (params['action']) {
          case "resizeWindow":
            window.dispatchEvent(new CustomEvent("anet.resize", { detail: params }));
            break;
          case "successfulSave":
            window.dispatchEvent(new CustomEvent("anet.save", { detail: params }));
            break;
          case "cancel":
            window.dispatchEvent(new CustomEvent("anet.cancel", { detail: params }));
            break;
          case "transactResponse":
            window.dispatchEvent(new CustomEvent("anet.transact", { detail: params }));
        }
      }

      this.showPayNowInfo = false;
      this.showAutoPayInfo = false;

      if (isPayment) {
        this.showAuthorizeNetPayNowIframe = true;
      }
      else {
        this.showAuthorizeNetAutoPayIframe = true;
      }

      if (isTest) {
        this.showAuthorizeNetTest = true;
      }
      else {
        this.showAuthorizeNetProd = true;
      }
      resolve(true);
    });
  }

  cancelAuthorizeNet() {
    this.showPayNowInfo = true;
    this.showAutoPayInfo = true;
    this.showAuthorizeNetPayNowIframe = false;
    this.showAuthorizeNetAutoPayIframe = false;
    this.showAuthorizeNetTest = false;
    this.showAuthorizeNetProd = false;
  }

  loadAuthorizeNetScript(isTest: boolean) {

    const prodUrl = "https://js.authorize.net/v3/AcceptUI.js"
    const testUrl = "https://jstest.authorize.net/v3/AcceptUI.js";

    var addUrl = isTest ? testUrl : prodUrl;
    var removeUrl = isTest ? prodUrl : testUrl;

    var isFound = false;
    var removeElement: HTMLScriptElement = null;
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes(addUrl)) {
        isFound = true;
      }
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes(removeUrl)) {
        removeElement = scripts[i];
      }
    }

    if (removeElement) {
      removeElement.remove();
    }

    if (!isFound) {
      var dynamicScripts = [addUrl];
      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    }
  }




  public onChangePaymentRadio(value: string) {
    if (value == PaymentRadio.Current) {
      this.disableCustomAmount = true;
    } else {
      this.disableCustomAmount = false;
    }
  }

  public continueToAutoPay() {
    this.disableContinueButton = true;
    const autoPayCommandRequest: AutoPayCommandRequest = {
      CustomerId: localStorage.getItem("AccountKey")
    }
    this.paymentService.PostAutoPay(autoPayCommandRequest).subscribe(res => {
      this.logService.debug("PostAutoPay", autoPayCommandRequest, res);
      this.token = res.Result.Token;
      this.encryptedParam = res.Result.EncryptedParam;
      this.extraData = res.Result.ExtraData;
      this.redirectUrl = res.Result.RedirectUrl;

      switch (this.payNowInfoQueryResult.GatewayType) {
        case "authorizenet":
        case "sandboxauthorizenet":
          {
            this.disableContinueButton = false;
            const isTest = "authorizenet".match(this.payNowInfoQueryResult.GatewayType) ? false : true;
            this.initAuthorizeNet(isTest, false);
            if (this.extraData) {
              this.authorizeNetProfileAction = "customer/editPayment";
            }
            setTimeout(function () {
              $("#send_profiletoken").submit();
            }, 1000);
            //$(".AcceptUI").click();
            break;
          }
        case "virtualterminal":
          $("#PayaTokenizedXml").val(this.token.toString());
          $("#RedirectUrl").val(this.redirectUrl.toString());
          this.redirectPostForm.nativeElement.submit();
          break;
      }


    }, error => {
        this.logService.error("PostAutoPay", error, autoPayCommandRequest);
    });
  }

  public cancelAutoPay() {
    this.disableContinueButton = true;
    this.disableContinueButtonIsAutopay = true;
    const cancelAutopayRequest: CancelAutoPayRequest = {
      CustomerId: localStorage.getItem("AccountKey")
    }

    this.logService.info("CancelAutoPay", "Start");
    this.paymentService.CancelAutoPay(cancelAutopayRequest).subscribe(res => {
      this.logService.debug("CancelAutoPay", cancelAutopayRequest, res);
      this.disableContinueButton = false;
      this.disableContinueButtonIsAutopay = false;
      this.loadAutoPayInfo();
      this.toastrService.success(res.Result.Message);
    }, error => {
        this.logService.error("CancelAutoPay", error);
        this.logService.debug("CancelAutoPay", cancelAutopayRequest);
    });
  }
}

enum PaymentRadio {
  Current = "current",
  Custom = "custom"
}

export interface PayNowInfoQuery {
  CustomerId: string
}

export interface PayNowInfoQueryResult {
  Account: string,
  CurrentBalance: number,
  FlowType: string,
  GatewayType: string
}

export interface PayNowCommandRequest {
  CustomerId: string,
  CurrentBalance: boolean,
  Amount: number
}

export interface PayNowCommandResult extends ActionResult {
  Token: string
  RedirectUrl: string
  Message: string
  PendingAmount: string,
  EncryptedParam: string
}

export interface PayNowCallbackCommandRequest {
  EncryptedParam: string,
  EncryptedResponse: string
}

export interface PayNowCallbackCommandResult extends ActionResult {
  Message: string
}

export interface CancelAutoPayRequest {
  CustomerId: string,
}

export interface CancelAutoPayResult extends ActionResult {
  Message: string;
}

export interface AutoPayInfoQuery {
  CustomerId: string
}

export interface AutoPayInfoResult {
  AccountNumber: string,
  CardOnFile: boolean
}

export interface AutoPayCommandRequest {
  CustomerId: string
}

export interface AutoPayCommandResult {
  Token: string
  RedirectUrl: string
  EncryptedParam: string
}

export interface AutoPayCallbackCommandRequest {
  EncryptedParam: string,
  EncryptedResponse: string
}

export interface AutoPayCallbackCommandResult extends ActionResult {
  Message: string
}

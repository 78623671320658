import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../authorize.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AccountService } from '../../app/modules/account/account-service';
import { LogService } from '../../app/core/services/log.service';
import { MenuItem, MenuService } from '../../app/core/services/menu.service';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.css']
})
export class LoginMenuComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  public fullname: string = null;
  public processing: boolean = false;
  private apiService: AccountService;
  public menuItems: MenuItem[];

  constructor(private authorizeService: AuthorizeService, _apiService: AccountService, private logService: LogService, private menuService: MenuService) {
    this.apiService = _apiService;
    this.menuItems = menuService.getUserMenu();
  }


  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));
    this.userName.subscribe(res => {   
      if (this.processing == false) {
        this.processing = true;
        this.logService.info("GetFullName", "Start");
        this.apiService.GetFullName(res).subscribe(res => {
          this.fullname = res.Result;
          this.logService.debug("GetFullName", res);
        }, error => {
            this.logService.error("GetFullName", error);
        });
      }
    });
  }
}

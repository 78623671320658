import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { AuthorizeService } from '../api-authorization/authorize.service';
import { ApplicationPaths } from '../api-authorization/api-authorization.constants';
import { Idle, DEFAULT_INTERRUPTSOURCES, AutoResume } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { LogService } from './core/services/log.service';
import { AppSettingsService } from './core/services/appsettings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';

  public isAuthenticated: Observable<boolean>;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  public modalRef: BsModalRef;
  @ViewChild('childModal') childModal: ModalDirective;

  constructor(
    private authorizeService: AuthorizeService,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private apiService: ApiService,
    private modalService: BsModalService,
    private logService: LogService,
    private appSettingsService: AppSettingsService
  ) {
  }

  initializeIdler() {
    // sets an idle timeout of x minutes.
    this.idle.setIdle(this.appSettingsService.settings.activityIdleTimeout);
    // sets a timeout period of 10 minutes. after x minutes of inactivity, the user will be considered timed out.
    this.idle.setTimeout(this.appSettingsService.settings.activityInactivityTimeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.setAutoResume(AutoResume.notIdle);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.logService.info("Idle", this.idleState);
      this.resetTimeout();
    });

    this.idle.onTimeout.subscribe(() => {
      this.childModal.hide();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logService.info("Idle", this.idleState);
      this.logout();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      this.logService.info("Idle", this.idleState);
      this.childModal.show();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.logService.info("Idle", countdown);
      if (Math.floor(countdown / 60) > 0) { 
        this.idleState = 'You will be logged out in ' + Math.floor(countdown / 60) + ' minutes and ' + (countdown % 60) + ' seconds!'
      } else {
        this.idleState = 'You will be logged out in ' + (countdown % 60) + ' seconds!'
      }
      this.logService.info("Idle", this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.authorizeService.isAuthenticated().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.idle.watch()
        this.timedOut = false;
      } else {
        this.idle.stop();
      }
    })
  }

  resetTimeout() {
    this.idle.watch();
    this.childModal.hide();
    this.timedOut = false;
  }

  logout() {
    this.authorizeService.signOut(ApplicationPaths.LoggedOut);
  }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
  }
}

export interface SiteSettingsQueryResult {
  Settings: SiteSettingsItem[];
}
export interface SiteSettingsItem {
  Key: string,
  Value: string
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { Notification, NotificationType } from '../../services/notification.model';
import { NotificationService } from '../../services/notification.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppSettingsService } from '../../services/appsettings.service';

@Component({
  selector: 'notification',
  templateUrl: 'notification.component.html',
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() id = 'default-notification';
  @Input() fade = true;

  notifications: Notification[] = [];
  notificationSubscription: Subscription;
  routeSubscription: Subscription;

  constructor(private router: Router, private notificationService: NotificationService) { }

  ngOnInit() {
    // subscribe to new notification notifications
    this.notificationSubscription = this.notificationService.onNotification(this.id)
      .subscribe(notification => {
        // clear notifications when an empty notification is received
        if (!notification.message) {
          // filter out notifications without 'keepAfterRouteChange' flag
          this.notifications = this.notifications.filter(x => x.keepAfterRouteChange);

          // remove 'keepAfterRouteChange' flag on the rest
          this.notifications.forEach(x => delete x.keepAfterRouteChange);
          return;
        }

        // add notification to array
        this.notifications.push(notification);

        // auto close notification if required
        if (notification.autoClose) {
          setTimeout(() => this.removeNotification(notification), 3000);
        }
      });

    // clear notifications on location change
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        //this.notificationService.clear(this.id);
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.notificationSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  removeNotification(notification: Notification) {
    // check if already removed to prevent error on auto close
    if (!this.notifications.includes(notification)) return;

    if (this.fade) {
      // fade out notification
      this.notifications.find(x => x === notification).fade = true;

      // remove notification after faded out
      setTimeout(() => {
        this.notifications = this.notifications.filter(x => x !== notification);
      }, 250);
    } else {
      // remove notification
      this.notifications = this.notifications.filter(x => x !== notification);
    }
  }

  cssClass(notification: Notification) {
    if (!notification) return;

    const classes = ['alert', 'alert-dismissable'];

    const notificationTypeClass = {
      [NotificationType.Success]: 'alert alert-success',
      [NotificationType.Error]: 'alert alert-danger',
      [NotificationType.Info]: 'alert alert-info',
      [NotificationType.Warning]: 'alert alert-warning'
    }

    classes.push(notificationTypeClass[notification.type]);

    if (notification.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }
}

<div class="sidebar-wrapper">
  <ul class="nav sidebarItemWithImageIcon">
    <app-account-selector></app-account-selector>
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
        <img src="{{menuitem.img}}" />
        <!--<i class="{{menuitem.icontype}}"></i>-->
        <p>{{menuitem.title}}</p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'">
        <img src="{{menuitem.img}}" />
        <!--<i class="{{menuitem.icontype}}"></i>-->
        <p>{{menuitem.title}}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children">
            <a [routerLink]="[menuitem.path, childitem.path]">
              <span class="sidebar-mini-icon">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li>
      <a [routerLink]='["/authentication/logout"]' [state]='{ local: true }'>
        <img src="./assets/img/icons/icon_logout.png" />
        <p>Logout</p>
      </a>
    </li>


  </ul>
</div>

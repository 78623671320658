<div class="main-content">
  <div class="row" *ngIf="!accountSummary">
    <mat-spinner color="accent" class="m-auto"></mat-spinner>
  </div>
  <div class="row" *ngIf="accountSummary">
    <div class="col-lg-5 offset-lg-1 col-md-6">
      <div class="card cardone">
        <div class="card-header">
           <div class="row">
             <div class="col-6">
               <h4 class="card-title m-0 header-type-1 firstColor">{{resource.Balance.Title}}</h4>
             </div>
             <div class="col-6">
               <img class="float-right full-height" src="./assets/img/icons/icon_balance.png" style="height:35px" />
             </div>
           </div>
          <hr />
        </div>
        <div class="card-body">
          <h3 class="card-title text-center header-type-2">${{ accountSummary.CurrentBalance | number : '1.2-2' }}</h3>
        </div>
        <div class="card-footer">
          <div class="row text-center">
            <div class="col-8 offset-2 mb-4" *ngIf="accountSummary.CurrentBalance >= 0.01">
              <a [routerLink]="['/pay/now']" class="btn btn-primary btn-round full-width font-size-20 font-weight-bold pl-0 pr-0">{{resource.Balance.PayButton}}</a>
            </div>
            <div class="col-8 offset-2 mb-2">
              <a [routerLink]="['/statements/overview']" class="btn btn-outline-primary btn-round full-width font-size-20 font-weight-bold pl-0 pr-0">{{resource.Balance.ViewStatementButton}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 col-md-6">
      <div class="card cardtwo">
        <div class="card-header">
          <div class="row">
            <div class="col-8">
              <h4 class="card-title m-0 header-type-1 firstColor">{{resource.Information.Title}}</h4>
            </div>
            <div class="col-4">
              <img class="float-right full-height" src="./assets/img/icons/icon_information.png" />
            </div>
          </div>
          <h4 class="card-title m-0 header-type-1 firstColor"></h4>
          <hr />
        </div>
        <div class="card-body">
          <p class="header-type-3 m-1">{{resource.Information.Entry1}} <span class="pull-right">{{ accountSummary.AccountName }}</span></p>
          <p class="header-type-3 m-1">{{resource.Information.Entry2}} <span class="pull-right">${{ accountSummary.CurrentBalance | number : '1.2-2'}}</span></p>
          <p class="header-type-3 m-1">{{resource.Information.Entry3}} <span class="pull-right">{{ accountSummary.LastLoginDate | date : 'mediumDate' }}</span></p>
          <p class="header-type-3 m-1">{{resource.Information.Entry4}} <span class="pull-right">{{ accountSummary.Account }}</span></p>
        </div>
        <div class="card-footer">

        </div>
      </div>
    </div>
    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.RecentPayment.Title}}</h4>
              </div>
            </div>
            <div class="col-sm-5">

            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="">
            <p *ngIf="accountSummary.RecentActivity.length == 0">{{resource.RecentPayment.NoRecentPaymentActivity}}</p>
            <table class="table" *ngIf="accountSummary.RecentActivity.length != 0">
              <tbody>
                <tr *ngFor="let recentActivity of accountSummary.RecentActivity">
                  <td class="header-type-2">{{ recentActivity.Date | date : 'mediumDate' }}</td>
                  <td class="header-type-2">{{ recentActivity.Description }}</td>
                  <td class="header-type-1 ">${{ recentActivity.Amount | number : '1.2-2' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

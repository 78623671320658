export class Notification {
  id: string;
  type: NotificationType;
  message: string;
  autoClose: boolean;
  keepAfterRouteChange: boolean;
  fade: boolean;

  constructor(init?: Partial<Notification>) {
    (<any>Object).assign(this, init);
  }
}

export enum NotificationType {
  Success,
  Error,
  Info,
  Warning
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaynowComponent } from './pages/paynow/paynow.component';
import { AuthorizeGuard } from '../../../api-authorization/authorize.guard';
import { PaynowCallbackComponent } from './pages/paynow-callback/paynow-callback.component';
import { AutoPayCallbackComponent } from './pages/autopay-callback/autopay-callback.component';
import { AutopayinfoComponent } from './pages/autopayinfo/autopayinfo.component';

const routes: Routes = [
  {
    path: 'now',
    component: PaynowComponent,
    canActivate: [AuthorizeGuard]
  },
  {
    path: 'autopay',
    component: AutopayinfoComponent,
    canActivate: [AuthorizeGuard]
  },
  {
    path: 'now-callback',
    component: PaynowCallbackComponent,
    canActivate: [AuthorizeGuard]
  },
  {
    path: 'autopay-callback',
    component: AutoPayCallbackComponent,
    canActivate: [AuthorizeGuard]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentRoutingModule {
  static components = [
    AutopayinfoComponent,
    PaynowComponent,
    PaynowCallbackComponent,
    AutoPayCallbackComponent
  ]
}

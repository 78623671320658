<nav #navbar class="navbar navbar-expand-lg navbar-absolute" [ngClass]="{ 'bg-white': open === true , 'navbar-transparent': open === false}">
  <div class="container-fluid">

    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" (click)="minimizeSidebar()">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle" (click)="sidebarToggle()">
        <button type="button" class="navbar-toggler">
          <span id="toggle-1" class="navbar-toggler-bar icon-bar bar1"></span>
          <span id="toggle-2" class="navbar-toggler-bar icon-bar bar2"></span>
          <span id="toggle-3" class="navbar-toggler-bar icon-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="javascript:void(0)"><h2>{{title}}</h2></a>
    </div>

    <!--<button class="navbar-toggler d-block d-sm-none" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" (click)="open = !open">
      <span id="kebab-1" class="navbar-toggler-bar navbar-kebab"></span>
      <span id="kebab-2" class="navbar-toggler-bar navbar-kebab"></span>
      <span id="kebab-3" class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]='["/user/settings"]'>
            <p>
              <span class="d-lg-none d-md-block">User Settings</span>
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://support.smartcitymetro.com/" target="_blank">
            <p>
              <span class="d-lg-none d-md-block">Support</span>
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]='["/authentication/logout"]' [state]='{ local: true }'>
            <p>
              <span class="d-lg-none d-md-block">Logout</span>
            </p>
          </a>
        </li>
      </ul>
    </div>-->

  </div>
</nav>


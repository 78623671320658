import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { AppSettings } from "./appsettings";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject } from '@angular/core';
import { LogService } from './log.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { isNumeric } from 'rxjs/internal/util/isNumeric';
import { isBoolean } from 'util';

@Injectable()
export class AppSettingsService {

  protected baseUrl: string;
  protected httpClient: HttpClient

  public settings: AppSettings;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(_httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, protected logService: LogService) {
    this.httpClient = _httpClient;
    this.baseUrl = _baseUrl
  }

  load(): Promise<AppSettings> {

    const promise = this.httpClient.get<ApiResponse<SiteSettingsQueryResult>>(this.baseUrl + 'api/site/settings', this.httpOptions)
      .toPromise()
      .then(data => {
        var obj: AppSettings = new AppSettings();

        obj.activityIdleTimeout = 300;
        obj.activityInactivityTimeout = 910;
        obj.maintenance = false;
        obj.siteMessage = "";
        obj.siteMessageStyle = "info";
        obj.siteMessageExpiration = -1;

        if (data && data.Result && data.Result.Settings) {
          if (data.Result.Settings.filter(x => x.Key == "Activity.IdleTimeout").length > 0) {
            var rawData = data.Result.Settings.filter(x => x.Key == "Activity.IdleTimeout")[0].Value;
            if (isNumeric(rawData)) {
              obj.activityIdleTimeout = Number(rawData);
            }
          }

          if (data.Result.Settings.filter(x => x.Key == "Activity.InactivityTimeout").length > 0) {
            var rawData = data.Result.Settings.filter(x => x.Key == "Activity.InactivityTimeout")[0].Value;
            if (isNumeric(rawData)) {
              obj.activityInactivityTimeout = Number(rawData);
            }
          }

          if (data.Result.Settings.filter(x => x.Key == "Maintenance").length > 0) {
            var rawData = data.Result.Settings.filter(x => x.Key == "Maintenance")[0].Value;
            if (rawData && "true" === rawData.toLowerCase()) {
              obj.maintenance = Boolean(rawData);
            }
          }

          if (data.Result.Settings.filter(x => x.Key == "SiteMessage").length > 0) {
            var rawData = data.Result.Settings.filter(x => x.Key == "SiteMessage")[0].Value;
            if (rawData) {
              obj.siteMessage = rawData;
            }
          }

          if (data.Result.Settings.filter(x => x.Key == "SiteMessageStyle").length > 0) {
            var rawData = data.Result.Settings.filter(x => x.Key == "SiteMessageStyle")[0].Value;
            if (rawData) {
              obj.siteMessageStyle = rawData;
            }
          }


          if (data && data.Result && data.Result.Settings) {
            if (data.Result.Settings.filter(x => x.Key == "SiteMessage.Expiration").length > 0) {
              var rawData = data.Result.Settings.filter(x => x.Key == "SiteMessage.Expiration")[0].Value;
              if (isNumeric(rawData)) {
                obj.siteMessageExpiration = Number(rawData);
              }
            }
          }
        }

        this.settings = obj;

        return obj;
      });

    return promise;
  }

  getSettingsFromApi(): Observable<ApiResponse<SiteSettingsQueryResult>> {
    this.logService.debug("GetUserSettings");
    return this.httpClient.get<ApiResponse<SiteSettingsQueryResult>>(this.baseUrl + 'api/site/settings', this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.logService.error("Api Error", errorMessage);
    return throwError(errorMessage);
  }
}

export interface ApiResponse<T> {
  Status: number,
  HttpStatus: number,
  Message: string,
  Result: T,
  Exception: object
}

export interface SiteSettingsQueryResult {
  Settings: SiteSettingsItem[];
}
export interface SiteSettingsItem {
  Key: string,
  Value: string
}

import { NgModule } from '@angular/core';
import { FixedPluginComponent } from './layout/fixedplugin/fixedplugin.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { HeaderComponent } from './layout/header/header.component';
import { AccountSelectorComponent } from './layout/account-selector/account-selector.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { NotificationComponent } from './layout/notification/notification.component';
import { ApiAuthorizationModule } from '../../api-authorization/api-authorization.module';
import { RouterModule } from '@angular/router';

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  RouterModule,
  ApiAuthorizationModule
]

const COMPONENTS = [
  FixedPluginComponent,
  FooterComponent,
  LayoutComponent,
  NavbarComponent,
  SidebarComponent,
  HeaderComponent,
  AccountSelectorComponent,
  NotificationComponent
]

@NgModule({
  imports: [...BASE_MODULES],
  exports: [...BASE_MODULES, ...COMPONENTS],
  declarations: [...COMPONENTS]
})
export class ThemeModule {
}

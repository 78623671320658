import { Injectable } from '@angular/core';

import { LogPublisher, LogConsole } from "./log.publisher";

@Injectable({
  providedIn: 'root'
})
export class LogPublisherService {
  constructor() {
    // Build publishers arrays
    this.buildPublishers();
  }

  // Public properties
  publishers: LogPublisher[] = [];

  // Build publishers array
  buildPublishers(): void {
    // Create instance of LogConsole Class
    this.publishers.push(new LogConsole());
  }
}

import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from '../navbar/navbar.component';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent, PlatformLocation } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { Observable } from 'rxjs';
import { AuthorizeService } from '../../../../api-authorization/authorize.service';
import { ApplicationPaths } from '../../../../api-authorization/api-authorization.constants';
import { AppSettingsService } from '../../services/appsettings.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit {
  private _router: Subscription;
  // url: string;
  url: string;
  location: Location;
  authorizeService: AuthorizeService;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  public isAuthenticated: Observable<boolean>;

  @ViewChild('sidebar') sidebar;
  @ViewChild(NavbarComponent) navbar: NavbarComponent;
  constructor(private router: Router, location: Location, authorizeService: AuthorizeService, public appSettingsService: AppSettingsService) {
    this.location = location;
    this.authorizeService = authorizeService;
  }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    //const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    //const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        }
        else
          window.scrollTo(0, 0);
      }
    });

    //this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
    //  elemMainPanel.scrollTop = 0;
    //  elemSidebar.scrollTop = 0;
    //});
    //const html = document.getElementsByTagName('html')[0];
    //if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
    //  let ps = new PerfectScrollbar(elemMainPanel);
    //  ps = new PerfectScrollbar(elemSidebar);
    //  html.classList.add('perfect-scrollbar-on');
    //}
    //else {
    //  html.classList.add('perfect-scrollbar-off');
    //}

/*    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      this.navbar.sidebarClose();
    });*/
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  navToLogin() {
    console.info("navto " + ApplicationPaths.LoginPathComponents);
    this.router.navigate(ApplicationPaths.LoginPathComponents);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { APP_BASE_HREF, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { BaseChartDirective } from 'ng2-charts';
import {  MatTooltipModule } from '@angular/material/tooltip';
import { HttpClientModule } from '@angular/common/http';
import { ChartModule } from 'primeng/chart';

@NgModule({
  imports: [
    CommonModule,
    AccountRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    // BaseChartDirective,
    MatTooltipModule,
    NgbTooltipModule,
    ChartModule
  ],
  declarations:
    [AccountRoutingModule.components],
  bootstrap: [],
  providers: [CurrencyPipe]
})

export class AccountModule { }

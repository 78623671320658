import { Component, Inject } from '@angular/core';
import { TitleService } from '../../../../core/services/title.service';
import { AccountService } from '../../account-service';
import { ActionResult } from '../../../../api.service';
import _resourcedata from '../../../../../assets/resources/resource.json';
import { LogService } from '../../../../core/services/log.service';

@Component({
    selector: 'app-account-overview',
    templateUrl: './account-overview.component.html',
    styleUrls: ['./account-overview.component.scss']
})
/** account-overview component*/
export class AccountOverviewComponent {
  public accountSummary: AccountSummaryQueryResult;
  private apiService: AccountService;
  private titleService: TitleService;
  private logService: LogService;
  private resource: object;

  constructor(_apiService: AccountService, _titleService: TitleService, _logService: LogService) {
    this.resource = _resourcedata.AccountOverview;
    this.apiService = _apiService;
    this.titleService = _titleService;
    this.logService = _logService;
    this.titleService.setTitle("Account Overview");
  }

  ngOnInit() {
    this.logService.info("GetAccountSummary", "Start");
    let customerId = localStorage.getItem("AccountKey");
    if (customerId != null && !"null".match(customerId)) {
      const accountSummaryPost: AccountSummaryPost = { CustomerId: localStorage.getItem("AccountKey") };
      console.info("ACCOUNT SUMMARY KEY: " + localStorage.getItem("AccountKey"));
      this.apiService.GetAccountSummary(accountSummaryPost).subscribe(res => {
        this.logService.debug("GetAccountSummary", accountSummaryPost, res);
        this.accountSummary = res.Result;
      }, error => {
        this.logService.error("GetAccountSummary", error, accountSummaryPost);
      });
    }
    else {
      this.logService.debug("Get Account Summary", "Account Number is null.");
    }
  }
}

export interface AccountSummaryPost {
  CustomerId: string
}

export interface AccountSummaryQueryResult extends ActionResult {
  Account: string,
  AccountType: string,
  AccountName: string,
  LastLoginDate: Date,
  CurrentBalance: number,
  RecentActivity: RecentActivity[]
}

export interface RecentActivity {
  Date: Date,
  Description: string,
  Amount: number
}

import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ThemeService } from '../../theme-service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import PerfectScrollbar from 'perfect-scrollbar';
import { LogService } from '../../services/log.service';

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    img?: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/profile/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'nc-icon nc-bank',
    img: "./assets/img/icons/icon_dashboard.png"
  },
  {
    path: '/profile/overview',
    title: 'Account Overview',
    type: 'link',
    icontype: 'nc-icon nc-atom',
    img: "./assets/img/icons/icon_overview.png"
  },
  {
    path: '/profile/detail',
    title: 'Billing Account Details',
    type: 'link',
    icontype: 'nc-icon nc-bank',
    img: "./assets/img/icons/icon_details.png"
  },
  {
    path: '/pay/now',
    title: 'Make A Payment',
    type: 'link',
    icontype: 'nc-icon nc-bank',
    img: "./assets/img/icons/icon_balance.png"
  },
  {
    path: '/statements/overview',
    title: 'Statements',
    type: 'link',
    icontype: 'nc-icon nc-bank',
    img: "./assets/img/icons/icon_statements.png"
  },
  {
    path: '/profile/dmca',
    title: 'DMCA Violations',
    type: 'link',
    icontype: 'nc-icon nc-bank',
    img: "./assets/img/icons/icon_dmca.png"
  },
];

@Component({
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar-component.scss']
})

export class SidebarComponent {
    private _router: Subscription;
    public menuItems: any[];
    sctMode$: boolean;

    constructor(
      private themeService: ThemeService,
      private router: Router,
      protected logService: LogService
    ) { }

    isNotMobileMenu(){
        if( window.outerWidth > 991){
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.themeService.setTheme("sct");
        this.sctMode$ = false;


        const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
          elemMainPanel.scrollTop = 0;
          elemSidebar.scrollTop = 0;
        });
        const html = document.getElementsByTagName('html')[0];
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
          let ps = new PerfectScrollbar(elemMainPanel);
          ps = new PerfectScrollbar(elemSidebar);
          html.classList.add('perfect-scrollbar-on');
        }
        else {
          html.classList.add('perfect-scrollbar-off');
        }
    }

    isMac(): boolean {
      let bool = false;
      if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
        bool = true;
      }
      return bool;
    }

  toggleThemeMode = function (event) {
      if (this.sctMode$) {
        this.themeService.setTheme("sct");
        this.sctMode$ = false;
      } else {
        this.themeService.setTheme("scn");
        this.sctMode$ = true;
      }
    }

}

<div class="main-content">
  <div class="row" *ngIf="!accountDashboard">
    <mat-spinner color="accent" class="m-auto"></mat-spinner>
  </div>
  <div class="row" *ngIf="accountDashboard">
    <div class="col-lg-3 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-body ">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center icon-warning">
                <i class="nc-icon nc-globe text-warning"></i>
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">{{resource.Account}}</p>
                <p class="card-title header-type-2">
                  {{ accountDashboard.AccountNumber }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6">
      <div class="card card-stats">
        <div class="card-body ">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center icon-warning">
                <i class="nc-icon nc-money-coins text-success"></i>
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">{{resource.Balance}}</p>
                <p class="card-title header-type-2">
                  ${{ accountDashboard.CurrentBalance | number : '1.2-2' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6">
      <div class="card card-stats"
        matTooltip="{{resource.Autopay_Tooltip}}">
        <div class="card-body ">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center icon-warning">
                <i class="nc-icon nc-credit-card text-danger"></i>
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">{{resource.Autopay}}</p>
                <p class="card-title header-type-2"
                    >
                  <span *ngIf="accountDashboard.EnrolledAutoPay">{{resource.Autopay_Enrolled}}</span>
                  <span *ngIf="!accountDashboard.EnrolledAutoPay">{{resource.Autopay_NotEnrolled}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6">
      <div class="card card-stats"
        matTooltip="{{accountDashboard.DmcaViolationTotal == 0 ? resource.DMCA_ViolationsToolTip0 : (resource.DMCA_ViolationsToolTipNon0.replace('{number}', accountDashboard.DmcaViolationTotal))}}" >
        <div class="card-body ">
          <div class="row">
            <div class="col-5 col-md-4">
              <div class="icon-big text-center icon-warning">
                <i class="nc-icon nc-alert-circle-i text-primary"></i>
              </div>
            </div>
            <div class="col-7 col-md-8">
              <div class="numbers">
                <p class="card-category">{{resource.DMCA_Violations}}</p>
                <p class="card-title header-type-2" [class.text-danger]="accountDashboard.DmcaViolationTotal > 1">
                  {{ accountDashboard.DmcaViolationTotal }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="accountDashboard">
    <div class="col-md-6 col-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-8">
              <div class="numbers pull-left">
                <h6 class="header-type-2 firstColor"><!-- :) --></h6>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="pull-right">
                <span class="badge badge-pill backgroundWithColorFirst" placement="bottom" [matTooltip]="balanceChartPercentageExplanation">
                  {{ balanceChartPercentage }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <h6 class="big-title">{{resource.StatementChartTitle}}</h6>
          <p-chart type="line"
                   [data]="balanceChartData"
                   [options]="balanceChartOptions"
                   [style]="{'width':'826px','height':'380px'}">
          </p-chart>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-sm-7">

            </div>
            <div class="col-sm-5">
              <div class="pull-right">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.RecentPayment.Title}}</h4>
              </div>
            </div>
            <div class="col-sm-5">

            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="" *ngIf="accountDashboard && accountDashboard.RecentActivity">
            <p *ngIf="accountDashboard.RecentActivity.length == 0">{{resource.RecentPayment.NoRecentPaymentActivity}}</p>
            <table class="table" *ngIf="accountDashboard.RecentActivity.length != 0">
              <tbody>
              <tr *ngFor="let recentActivity of accountDashboard.RecentActivity">
                <td class="header-type-2">{{ recentActivity.Date | date : 'mediumDate' }}</td>
                <td class="header-type-2">{{ recentActivity.Description }}</td>
                <td class="header-type-1 ">${{ recentActivity.Amount | number : '1.2-2' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <p *ngIf="!accountDashboard || !accountDashboard.RecentActivity">No recent activity</p>
        </div>
      </div>
      <div class="card">
        <div class="card-header py-2 px-4">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">
                  {{resource.LastLogin}}
                  <span class="header-type-1">{{ accountDashboard.LastLogin | date : 'longDate' }}</span>
                </h4>
              </div>
            </div>
            <div class="col-sm-5">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




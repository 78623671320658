<li class="accountItem">
  <a class="currentAccountAnchor" id="currentAccountAnchor" *ngIf="currentAccount" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
    <p class="full-width m-0">
      Acct# {{currentAccount.AccountNumber}}
      <span>
        <i class="fa fa-angle-down m-0"></i>
      </span>
      <br>
      {{currentAccount.FirstName}} {{currentAccount.LastName}}
      <br>
      {{currentAccount.Address}}
    </p>
  </a>
  <div class="selectAccount collapse" id="collapseExample">

    <div class="cycleHeader" *ngIf="cycleAccess">
      <p>SEARCH</p>
    </div>
    <div class="accountSearch" *ngIf="cycleAccess">
      <input type="text" class="form-control" id="inputCycleSearch" [(ngModel)]="searchQueryString" (keyup)="searchCycleAccounts($event.target.value)" placeholder="Search for Additional Accounts...">
    </div>
    <hr class="m-0 p-0" *ngIf="cycleAccess && !searchInProgress && cycleSearchList?.length > 0">
    <div class="accountsList" *ngFor="let account of cycleSearchList;let i = index">
      <p class="{{currentAccount.AccountNumber == account.AccountNumber ? 'active' : ''}}" (click)="changeToCycleAccount(account.AccountNumber)">Account # {{account.AccountNumber}}<br />{{account.FirstName}} {{account.LastName}}<br />{{account.Address}}</p>
      <hr class="m-0 p-0">
    </div>
    <div class="accountsList" *ngIf="cycleAccess && !searchInProgress && (cycleSearchList == null || cycleSearchList.length == 0) && searchIsDirty">
      <p>- No Results -</p>
    </div>
    <div class="accountsList" *ngIf="cycleAccess && searchInProgress">
      <p>Searching...</p>
    </div>

    <div class="metroHeader" *ngIf="metroAccountList?.length > 0">
      <p>SMART CITY METRO</p>
    </div>
    <div class="accountsList" *ngFor="let account of metroAccountList;let i = index">
      <p class="{{currentAccount.AccountNumber == account.AccountNumber ? 'active' : ''}}" (click)="changeAccount(account.AccountNumber)">Account # {{account.AccountNumber}}<br />{{account.FirstName}} {{account.LastName}}<br />{{account.Address}}</p>
      <hr class="m-0 p-0">
    </div>

    <div class="telecomHeader" *ngIf="telecomAccountList?.length > 0">
      <p>SMART CITY TELECOM</p>
    </div>
    <div class="accountsList" *ngFor="let account of telecomAccountList;let i = index">
      <p class="{{currentAccount.AccountNumber == account.AccountNumber ? 'active' : ''}}" (click)="changeAccount(account.AccountNumber)">Account # {{account.AccountNumber}}<br />{{account.FirstName}} {{account.LastName}}<br />{{account.Address}}</p>
      <hr class="m-0 p-0">
    </div>

  </div>
</li>


<div class="main-content">
  <div class="row" *ngIf="!autoPayInfoResult">
    <mat-spinner color="accent" class="m-auto"></mat-spinner>
  </div>
  <div class="row" *ngIf="autoPayInfoResult">
    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title header-type-1 firstColor m-0">Auto Pay Info</h5>
          <hr />
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <p *ngIf="hasCardOnFile" class="header-type-1 firstColor text-center">You have a card on file.</p>
              <p *ngIf="!hasCardOnFile" class="header-type-1 secondColor text-center">You do not have a card on file.</p>
            </div>
          </div>
        </div>
        <div class="card-footer text-center">
          <button [disabled]="disableContinueButton" (click)="continueToAutoPay()" class="btn btn-primary btn-round font-size-20 font-weight-bold m-3 w-25">{{hasCardOnFile ? "UPDATE": "SET UP"}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<form hidden #redirectPostForm action="https://www.sageexchange.com/sevd/frmpayment.aspx" method='POST'>
  <input type="hidden" name="request" id="PayaTokenizedXml" />
  <input type="hidden" name="consumer_initiated" value="True">
  <input type="hidden" name="redirect_url" value="" id="RedirectUrl" />
  <input type="submit" value="Submit" />
  <button type="submit"></button>
</form>

import { NgModule } from '@angular/core';
import { PaymentRoutingModule } from './payment-routing.module';
import {CommonModule, CurrencyPipe} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    PaymentRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressSpinnerModule
  ],
  declarations:
    [PaymentRoutingModule.components],
  providers: [
    CurrencyPipe // Add CurrencyPipe to providers
  ],
  bootstrap: []
})
export class PaymentModule {
}

<div class="main-content">
  <div class="row">
    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title header-type-1 firstColor m-0">Auto Pay Set Up</h5>
          <hr />
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 text-center">
              <p *ngIf="response == 'error'" class="header-type-1 secondColor text-center">Failed to set up card on file</p>
              <p *ngIf="response == 'success'" class="header-type-1 firstColor text-center">Card on file successfully set up.</p>
              <a *ngIf="response == 'error'" [routerLink]="['/pay/now']" class="btn btn-primary btn-round full-width font-size-20 font-weight-bold pl-0 pr-0">Try again</a>
              <a *ngIf="response == 'success'" [routerLink]="['/profile/dashboard']" class="btn btn-outline-primary btn-round full-width font-size-20 font-weight-bold pl-0 pr-0">Go to Dashboard</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

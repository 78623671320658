import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './pages/index/index.component';
import { NgModule } from '@angular/core';
import { AuthorizeGuard } from '../../../api-authorization/authorize.guard';

const routes: Routes = [
  { path: 'index', component: IndexComponent, canActivate: [AuthorizeGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {
  static components = [
    IndexComponent
  ]
}

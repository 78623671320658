<div *ngIf="notifications?.length > 0" class="row mt-3">
  <div class="col-10 offset-1">
    <div *ngFor="let notification of notifications" class="{{cssClass(notification)}}" [@simpleFadeAnimation]="'in'">
      <button type="button" aria-hidden="true" class="close" data-dismiss="notification" aria-label="Close" (click)="removeNotification(notification)">
        <i class="nc-icon nc-simple-remove"></i>
      </button>
      <span [innerHTML]="notification.message"></span>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor() {

  }

  getUserMenu(): MenuItem[] {
    var menu: MenuItem[] = [];

    menu.push({
      title: "User Settings",
      routerLink: ["/user/settings"]
    } as MenuItem);

    menu.push({
      title: "Support",
      href: "https://support.smartcitymetro.com/",
      target: "_blank"
    } as MenuItem);

    menu.push({
      title: "Logout",
      routerLink: ["/authentication/logout"],
      state: { local: true }
    } as MenuItem);

    return menu;
  }
}

export interface MenuItem {
  title: string;
  routerLink: any;
  href: string;
  target: string;
  state: any;
}

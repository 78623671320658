<div class="main-content">
  <div class="row" *ngIf="!dmcaListResult">
    <mat-spinner color="accent" class="m-auto"></mat-spinner>
  </div>
  <div class="row" *ngIf="dmcaListResult">
    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h5 class="card-title m-0 header-type-1 firstColor">{{resource.Instructions.Title}}</h5>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">

              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="">
            <p>
              Smart City is committed to complying with U.S. copyright and related laws, and requires all customers and users of the Service to comply with these laws.
            </p>
            <p>
              Accordingly, you may not store any material or content on, or disseminate any material or content over, the Service (or any part of the Service) in any manner that constitutes an infringement of third party intellectual property rights, including rights granted by U.S. copyright law. Owners of copyrighted works who believe that their rights under U.S. copyright law have been infringed may take advantage of certain provisions of the Digital Millennium Copyright Act of 1998 (the “DMCA”) to report alleged infringements. It is Smart City’s policy in accordance with the DMCA and other applicable laws to reserve the right to terminate the Service provided to any customer or user who is either found to infringe third party copyright or other intellectual property rights, including repeat infringers, or who Smart City believes in its sole discretion is infringing these rights. Smart City may terminate the Service at any time with or without notice for any affected customer or user.
            </p>
            <p>
              <a href="https://www.smartcitymetro.com/copyright-digital-millennium-copyright-act-dmca-enforcement-policy/" target="_blank">Copyright and Digital Millennium Copyright Act (DMCA) Enforcement Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-10 offset-1">
      <div class="card">
        <div class="card-body">
          <div id="accordion" role="tablist" aria-multiselectable="true" class="card-collapse">
            <h5 class="card-title m-0 header-type-1 secondColor">{{resource.ViolationList.Title}}</h5>

            <p *ngIf="dmcaListResult.Violations.length == 0">{{resource.ViolationList.NoViolations}}</p>

            <div class="card card-plain" *ngFor="let Violation of dmcaListResult.Violations; let i = index">
              <div class="card-header pt-2 pb-2" role="tab" id="{{ 'heading' + i }}">
                <a class="collapsed header-type-3" data-toggle="collapse" data-parent="#accordion" href="{{ '#collapse' + i }}" aria-expanded="false">
                  {{Violation.CreatedDate | date : 'longDate'}}
                  <span class="float-right header-type-3 secondColor">
                    {{resource.ViolationList.ViewViolationButton}}&nbsp;&nbsp;&nbsp;<i class="nc-icon nc-minimal-down"></i>
                  </span>
                </a>
              </div>
              <div id="{{ 'collapse' + i }}" class="collapse" role="tabpanel">
                <div class="card-body">
                  <h6 class="fifthColor">
                    Violation Date: {{Violation.CreatedDate | date : 'longDate'}}<br />
                    Ticket Id: {{ Violation.OrderLinkId }}<br />
                    Description: {{Violation.ReportDescription }}<br />
                    <span *ngIf="Violation.HasDetail == true">
                      Case: {{ Violation.CaseId }}<br />
                      <br />
                      Complainant:<br />
                      {{ Violation.ComplainantEntity }}<br />
                      {{ Violation.ComplainantContact }}<br />
                      {{ Violation.ComplainantAddress }}<br />
                      {{ Violation.ComplainantPhone }}<br />
                      {{ Violation.ComplainEmail }}
                    </span>
                    <span *ngIf="Violation.HasDetail == true && Violation.Content.length > 0">
                      <br />Content:<br />
                      <span *ngFor="let Content of Violation.Content">
                        {{ Content.Title }}<br />
                        {{ Content.FileName }}<br />
                        {{ Content.Timestamp }}<br />
                      </span>
                    </span>
                  </h6>
                  <div class="text-center">
                    <button *ngIf="!Violation.AcknowledgeDate" (click)="AcknowledgeViolation(Violation)" class="btn btn-primary btn-round font-weight-bold">{{resource.ViolationList.AcknowledgeButton}}</button>
                    <button *ngIf="Violation.HasLetter" (click)="DownloadDMCALetter(Violation)" class="btn btn-primary btn-round font-weight-bold">{{resource.ViolationList.ViewLetterButton}}</button>
                  </div>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ApiService, ActionResult } from '../../../../api.service';
import { TitleService } from '../../../../core/services/title.service';
import { AccountService } from '../../account-service';
import { ToastrService } from 'ngx-toastr';
import _resourcedata from '../../../../../assets/resources/resource.json';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import { LogService } from '../../../../core/services/log.service';

@Component({
  selector: 'app-dmca',
  templateUrl: './dmca.component.html',
  styleUrls: ['./dmca.component.scss']
})
/** DMCA component*/
export class DmcaComponent {
  private apiService: AccountService;
  private titleService: TitleService;
  private toastrService: ToastrService;
  private logService: LogService;
  public dmcaListResult: DmcaListResult;
  public violation: Violation;
  private resource: object;

  /** DMCA ctor */
  constructor(_apiService: AccountService, _titleService: TitleService, _toastService: ToastrService,
    _logService: LogService) {
    this.titleService = _titleService;
    this.titleService.setTitle("DMCA Violations");
    this.apiService = _apiService;
    this.resource = _resourcedata.DMCA;
    this.toastrService = _toastService;
    this.logService = _logService;

    const accountSummaryPost: DmcaListQuery = { CustomerId: localStorage.getItem("AccountKey") };
    this.logService.info("DmcaListQuery", "Start");
    this.apiService.GetDMCAList(accountSummaryPost).subscribe(res => {
      this.logService.debug("DmcaListQuery", accountSummaryPost, res);
      this.dmcaListResult = res.Result;
    }, error => {
        this.logService.error("DmcaListQuery", error, accountSummaryPost);
    });
  }

  showViolation(OrderLinkId: number) {
    this.violation = this.dmcaListResult.Violations.find(x => x.OrderLinkId == OrderLinkId);
  }

  AcknowledgeViolation(violation: Violation) {
    const dmcaAcknowledgeCommand: DmcaAcknowledgeCommand = { CustomerId: localStorage.getItem("AccountKey"), OrderLinkId: violation.OrderLinkId };
    this.logService.info("DmcaAcknowledgeCommand", "Start");
    this.apiService.AcknowledgeViolation(dmcaAcknowledgeCommand).subscribe(res => {
      this.logService.debug("DmcaAcknowledgeCommand", dmcaAcknowledgeCommand, res);
      if (res.Result.Success) {
        this.toastrService.success("Violation Acknowledged", null, { positionClass: 'toast-bottom-center' });
        this.dmcaListResult.Violations.find(x => x.OrderLinkId == violation.OrderLinkId).AcknowledgeDate = new Date();
      } else {
        this.toastrService.error("Error ocurred while trying to acknowledge violation. Please try again.", null, { positionClass: 'toast-bottom-center' });
      }
    }, error => {
        this.logService.error("DmcaAcknowledgeCommand", error, dmcaAcknowledgeCommand);
    });
  }

  DownloadDMCALetter(violation: Violation) {
    const downloadDMCALetter: DownloadDMCALetter = { CustomerId: localStorage.getItem("AccountKey"), OrderLinkId: violation.OrderLinkId };
    this.logService.info("DownloadDMCALetter", "Start");
    this.apiService.DownloadDMCALetter(downloadDMCALetter)
      .subscribe(
        success => {
          this.logService.debug("DownloadDMCALetter", "Success", downloadDMCALetter);
          FileSaver.saveAs(success, "copy_right_" + violation.OrderLinkId);
        },
        err => {
          this.logService.error("DownloadDMCALetter", "Server error while downloading file.", downloadDMCALetter);
        }
      );
  }
}

export interface DmcaListQuery {
  CustomerId: string
}

export interface DmcaListResult extends ActionResult {
  Violations: Violation[]
}

export interface Violation {
  OrderLinkId: number,
  AccountNumber: string,
  CreatedDate: Date,
  ReportDescription: string,
  AcknowledgeDate: Date,
  HasDetail: boolean,
  CaseId: string,
  ComplainantEntity: string,
  ComplainantContact: string,
  ComplainantAddress: string,
  ComplainantPhone: string,
  ComplainantEmail: string,
  ServiceProviderEntity: string,
  ServiceProviderEmail: string,
  SourceTimestamp: Date,
  SourceIPAddress: string,
  SourcePort: string,
  SourceType: string,
  SourceNumberOfFiles: string,
  HasLetter: boolean;
  Content: DmcaContentItem[]
}

export interface DmcaContentItem {
  Timestamp: Date,
  Title: string,
  FileName: string,
  FileSize: string,
  Hash: string,
  HashType: string
}

export interface DownloadDMCALetter {
  CustomerId: string;
  OrderLinkId: number;
}

export interface DmcaAcknowledgeCommand {
  CustomerId: string;
  OrderLinkId: number;
}
export interface DmcaAcknowledgeResponse extends ActionResult {

}

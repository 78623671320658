import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';
import { AuthorizeGuard } from '../../../api-authorization/authorize.guard';

const routes: Routes = [
  {
    path: 'settings',
    component: UserSettingsComponent,
    canActivate: [AuthorizeGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {
  static components = [
    UserSettingsComponent
  ]
}

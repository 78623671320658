<div class="main-content" *ngIf="!loaded">
  <div class="row">
    <mat-spinner color="accent" class="m-auto"></mat-spinner>
  </div>
</div>
<div class="main-content" *ngIf="loaded">

  <!-- Pay Now Information -->
  <div class="row" *ngIf="payNowInfoQueryResult && showPayNowInfo">
    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title header-type-1 firstColor m-0">{{resource.Title}}</h5>
          <hr />
        </div>
        <div class="card-body" *ngIf="!disableContinueButton">
          <div class="row" *ngIf="payNowInfoQueryResult.CurrentBalance == 0">
            <div class="col-12">
              <p class="header-type-1 firstColor text-center">{{resource.NoBalance}}</p>
            </div>
          </div>
          <div class="row" *ngIf="payNowInfoQueryResult.CurrentBalance != 0">

            <div class="col-12 mb-3">
              <div class="float-left">

                <div class="form-check-radio">
                  <label class="form-check-label">
                    <input [(ngModel)]="paymentRadio" (ngModelChange)="onChangePaymentRadio($event)" value="current" class="form-check-input" id="currentBalance" name="paymentSelector" type="radio">{{resource.CurrentBalance}}
                    <span class="form-check-sign"></span>
                  </label>
                </div>

              </div>
              <div class="float-right">
                <p class="fifthColor">${{payNowInfoQueryResult.CurrentBalance}}</p>
              </div>
            </div>

            <div class="col-12">
              <div class="float-left">

                <div class="form-check-radio">
                  <label class="form-check-label">
                    <input [(ngModel)]="paymentRadio" (ngModelChange)="onChangePaymentRadio($event)" value="custom" class="form-check-input" id="enterAmount" name="paymentSelector" type="radio">{{resource.CustomAmount}}
                    <span class="form-check-sign"></span>
                  </label>
                </div>

              </div>

              <div class="float-right">
                <div class="form-group">
                  <input #customAmountInput [disabled]="disableCustomAmount" (blur)="transformAmount($event)" [(ngModel)]="formattedAmount" placeholder="$0.00" type="text" class="font-size-20 form-control text-right">
                </div>
              </div>

            </div>

          </div>
        </div>
        <div class="card-footer text-center" *ngIf="payNowInfoQueryResult.CurrentBalance != 0">
          <p class="header-type-1 firstColor" *ngIf="disableContinueButton">You are now being redirected to our secure payment processing tool.</p>
          <button [disabled]="disableContinueButton" (click)="continueToPay()" class="btn btn-primary btn-round font-size-20 font-weight-bold m-3 w-25">{{resource.PayButton}}</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Spreedly Pay Now IFrame -->
  <div class="row" *ngIf="showSpreedlyPayNowIframe">
    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h5 class="card-title header-type-1 firstColor m-0">{{resource.Title}}</h5>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <div class="col-12 mb-3">

            <input type="hidden" name="paynow_payment_method_token" id="paynow_payment_method_token">

            <div class="form-group has-label">
              <label for="" class="control-label">
                Name
                <span class="star">*</span>
              </label>
              <div class="row">
                <div class="col-md-4">
                  <input #SpreedlyPayNowInputName="ngModel" name="spreedlyPayNowInputName" [(ngModel)]="spreedlyPayNowInputName" type="text" class="form-control" placeholder="Name" required>
                </div>
              </div>
              <small [hidden]="SpreedlyPayNowInputName.valid || (SpreedlyPayNowInputName.pristine)" class="text-danger">
                Name is required
              </small>
            </div>

            <div class="form-group has-label">
              <label for="" class="control-label">
                Credit Card Number
                <span class="star">*</span>
              </label>
              <div class="row">
                <div class="col-md-4">
                  <div id="spreedly-pawnow-number" class="form-control spreedly-control"></div>
                </div>
              </div>
            </div>

            <div class="form-group has-label">
              <label for="" class="control-label">
                Expiration Date
                <span class="star">*</span>
              </label>
              <div class="row">
                <div class="col-md-2">
                  <input #SpreedlyPayNowInputMonth="ngModel" name="spreedlyPayNowInputMonth" [(ngModel)]="spreedlyPayNowInputMonth" type="text" class="form-control" placeholder="MM" maxlength="2" required>
                </div>
                <div class="col-md-2">
                  <input #SpreedlyPayNowInputYear="ngModel" name="spreedlyPayNowInputYear" [(ngModel)]="spreedlyPayNowInputYear" type="text" class="form-control" placeholder="YYYY" maxlength="4" required>
                </div>
              </div>
              <small [hidden]="(SpreedlyPayNowInputMonth.valid && SpreedlyPayNowInputYear.valid) || (SpreedlyPayNowInputMonth.pristine && SpreedlyPayNowInputYear.pristine)" class="text-danger">
                Expiration is required
              </small>
            </div>

            <div class="form-group has-label">
              <label for="" class="control-label">
                CVV
                <span class="star">*</span>
              </label>
              <div class="row">
                <div class="col-md-2">
                  <div id="spreedly-paynow-cvv" class="form-control spreedly-control"></div>
                </div>
              </div>
            </div>


          </div>
        </div>
        <div class="card-footer text-center">
          <button #spreedlyPayNowSubmitButton (click)="submitSpreedlyPayNow()" id="submit-paynow-button" class="btn btn-primary btn-round font-size-20 font-weight-bold m-3" disabled>Pay Now</button>
          <button (click)="cancelSpreedlyPayNow()" class="btn btn-danger btn-round font-size-20 font-weight-bold m-3">Go Back</button>
        </div>
      </div>
    </div>
  </div>

  <!-- ANET Pay Now IFrame -->
  <div class="row" *ngIf="showAuthorizeNetPayNowIframe">
    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h5 class="card-title header-type-1 firstColor m-0">{{resource.Title}}</h5>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <div class="col-12 mb-3">
            <div class="float-left">
              <p>Amount</p>
            </div>
            <div class="float-right">
              <p class="fifthColor">${{amountToPay}}</p>
            </div>
          </div>
          <iframe id="load_payment" class="embed-responsive-item" name="load_payment" style="width:100%;height:650px" width="100%" height="650" frameborder="0" scrolling="no">
          </iframe>
          <form *ngIf="showAuthorizeNetProd" id="send_paymenttoken" action="https://test.authorize.net/payment/payment" method="post" target="load_payment">
            <input type="hidden" name="token" value="{{token}}" />
          </form>
          <form *ngIf="showAuthorizeNetTest" id="send_paymenttoken" action="https://test.authorize.net/payment/payment" method="post" target="load_payment">
            <input type="hidden" name="token" value="{{token}}" />
          </form>
        </div>
      </div>
    </div>
  </div>


  <!-- AutoPay Information -->
  <div class="row" *ngIf="autoPayInfoResult && showAutoPayInfo">
    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.Autopay.Title}}</h4>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <p *ngIf="hasCardOnFile && !disableContinueButton" class="header-type-1 firstColor text-center">{{resource.Autopay.CardOnFile}}</p>
          <p *ngIf="!hasCardOnFile && !disableContinueButton" class="header-type-1 secondColor text-center">{{resource.Autopay.NoCardOnFile}}</p>
          <p class="header-type-1 firstColor text-center" *ngIf="disableContinueButton && (!disableContinueButtonIsAutopay)">{{resource.Autopay.RedirectMessage}}</p>
          <p class="header-type-1 firstColor text-center" *ngIf="disableContinueButton && disableContinueButtonIsAutopay">{{resource.Autopay.CancellingAutoPay}}</p>
        </div>
        <div class="card-footer text-center">
          <button [disabled]="disableContinueButton" (click)="continueToAutoPay()" class="btn btn-primary btn-round font-size-20 font-weight-bold m-3 autopayBtn">{{hasCardOnFile ? resource.Autopay.ChangeAutoPay : resource.Autopay.SetUpAutoPay}}</button>
          <button *ngIf="hasCardOnFile" [disabled]="disableContinueButton" (click)="cancelAutoPay()" class="btn btn-danger btn-round font-size-20 font-weight-bold m-3 autopayBtn">{{ resource.Autopay.CancelAutoPay }} </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Spreedly AutoPay IFrame -->
  <div class="row" *ngIf="showSpreedlyAutoPayIframe">
    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h5 class="card-title header-type-1 firstColor m-0">{{resource.Autopay.Title}}</h5>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <div class="col-12 mb-3">

            <input type="hidden" name="payment_method_token" id="payment_method_token">

            <div class="form-group has-label">
              <label for="" class="control-label">
                Name
                <span class="star">*</span>
              </label>
              <div class="row">
                <div class="col-md-4">
                  <input #SpreedlyAutoPayInputName="ngModel" name="spreedlyAutoPayInputName" [(ngModel)]="spreedlyAutoPayInputName" type="text" class="form-control" placeholder="Name" required>
                </div>
              </div>
              <small [hidden]="SpreedlyAutoPayInputName.valid || (SpreedlyAutoPayInputName.pristine)" class="text-danger">
                Name is required
              </small>
            </div>

            <div class="form-group has-label">
              <label for="" class="control-label">
                Credit Card Number
                <span class="star">*</span>
              </label>
              <div class="row">
                <div class="col-md-4">
                  <div id="spreedly-autopay-number" class="form-control spreedly-control"></div>
                </div>
              </div>
            </div>

            <div class="form-group has-label">
              <label for="" class="control-label">
                Expiration Date
                <span class="star">*</span>
              </label>
              <div class="row">
                <div class="col-md-2">
                  <input #SpreedlyAutoPayInputMonth="ngModel" name="spreedlyAutoPayInputMonth" [(ngModel)]="spreedlyAutoPayInputMonth" type="text" class="form-control" placeholder="MM" maxlength="2" required>
                </div>
                <div class="col-md-2">
                  <input #SpreedlyAutoPayInputYear="ngModel" name="spreedlyAutoPayInputYear" [(ngModel)]="spreedlyAutoPayInputYear" type="text" class="form-control" placeholder="YYYY" maxlength="4" required>
                </div>
              </div>
              <small [hidden]="(SpreedlyAutoPayInputMonth.valid && SpreedlyAutoPayInputYear.valid) || (SpreedlyAutoPayInputMonth.pristine && SpreedlyAutoPayInputYear.pristine)" class="text-danger">
                Expiration is required
              </small>
            </div>

            <div class="form-group has-label">
              <label for="" class="control-label">
                CVV
                <span class="star">*</span>
              </label>
              <div class="row">
                <div class="col-md-2">
                  <div id="spreedly-autopay-cvv" class="form-control spreedly-control"></div>
                </div>
              </div>
            </div>


          </div>
        </div>
        <div class="card-footer text-center">
          <button #spreedlyAutoPaySubmitButton (click)="submitAutoPaySpreedly()" id="submit-autopay-button" class="btn btn-primary btn-round font-size-20 font-weight-bold m-3" disabled>Submit Autopay</button>
          <button (click)="cancelAutopaySpreedly()" class="btn btn-danger btn-round font-size-20 font-weight-bold m-3">Go Back</button>
        </div>
      </div>
    </div>
  </div>

  <!-- ANET Auto Pay IFrame -->
  <div class="row" *ngIf="showAuthorizeNetAutoPayIframe">
    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h5 class="card-title header-type-1 firstColor m-0">{{resource.Autopay.Title}}</h5>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <iframe id="load_profile" class="embed-responsive-item" name="load_profile" style="width:100%;height:650px" width="100%" height="650" frameborder="0" scrolling="no">
          </iframe>
          <form *ngIf="showAuthorizeNetProd" id="send_profiletoken" action="https://test.authorize.net/{{authorizeNetProfileAction}}" method="post" target="load_profile">
            <input type="hidden" name="token" value="{{token}}" />
            <input *ngIf="extraData" type="hidden" name="paymentProfileId" value="{{extraData}}" />
          </form>
          <form *ngIf="showAuthorizeNetTest" id="send_profiletoken" action="https://test.authorize.net/{{authorizeNetProfileAction}}" method="post" target="load_profile">
            <input type="hidden" name="token" value="{{token}}" />
            <input *ngIf="extraData" type="hidden" name="paymentProfileId" value="{{extraData}}" />
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- End Main Content -->
</div>

<!-- Virtual Terminal -->
<!-- <form *ngIf="showVirtualTerminal" hidden #redirectPostForm action="https://www.sageexchange.com/sevd/frmpayment.aspx" method='POST'>
  <input type="hidden" name="request" id="PayaTokenizedXml" />
  <input type="hidden" name="consumer_initiated" value="True">
  <input type="hidden" name="redirect_url" value="" id="RedirectUrl" />
  <input type="submit" value="Submit" />
  <button type="submit"></button>
</form> -->
<form hidden #redirectPostForm action="https://www.sageexchange.com/sevd/frmpayment.aspx" method='POST'>
  <input type="hidden" name="request" id="PayaTokenizedXml" />
  <input type="hidden" name="consumer_initiated" value="True">
  <input type="hidden" name="redirect_url" value="" id="RedirectUrl" />
  <input type="submit" value="Submit" />
  <button type="submit"></button>
</form>



<div *ngIf="showAuthorizeNetTest">

  <!--<div type="button"
       class="AcceptUI"
       data-billingAddressOptions='{"show":true, "required":true}'
       data-apiLoginID="62p9R7VuybA"
       data-clientKey="2VuGq7vxFyLUVPJHpW4mkSE2rkH65Usu9B2W3433akEebH8GqUW58Vqua4HK4C4c"
       data-acceptUIFormBtnTxt="Submit"
       data-acceptUIFormHeaderTxt="Card Information"
       data-responseHandler="responseHandler"></div>-->
</div>

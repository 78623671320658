import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountDetailComponent } from './pages/account-detail/account-detail.component';
import { AccountOverviewComponent } from './pages/account-overview/account-overview.component';
import { DmcaComponent } from './pages/dmca/dmca.component';
import { AuthorizeGuard } from '../../../api-authorization/authorize.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthorizeGuard]
  },
  {
    path: 'detail',
    component: AccountDetailComponent,
    canActivate: [AuthorizeGuard]
  },
  {
    path: 'overview',
    component: AccountOverviewComponent,
    canActivate: [AuthorizeGuard]
  },
  {
    path: 'dmca',
    component: DmcaComponent,
    canActivate: [AuthorizeGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {
  static components = [
    AccountDetailComponent,
    AccountOverviewComponent,
    DmcaComponent,
    DashboardComponent
  ]
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private title = new BehaviorSubject('MyAccount');
  currentTitle = this.title.asObservable();
  constructor(private titleService: Title) {

  }
  setTitle(newTitle: string) {
    this.title.next(newTitle)
    this.titleService.setTitle(newTitle);
  }
}

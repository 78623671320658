import { NgModule } from '@angular/core';


@NgModule({
  imports: [ ],
  exports: [ ],
  declarations: [ ]
})
export class CoreModule {
}

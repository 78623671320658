<div class="avatar">
  <a *ngIf="!(isAuthenticated | async)" class="nav-link text-dark" [routerLink]='["/authentication/register"]'>Register</a>

  <a *ngIf="!(isAuthenticated | async)" class="nav-link text-dark" [routerLink]='["/authentication/login"]'>Login</a>


  <p *ngIf="(isAuthenticated | async)">{{ fullname }}</p>
  <ul class="navbar">
    <li class="nav-item btn-rotate dropdown">
      <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">
        <i class="fa fa-cog firstColor"></i>
      </a>

      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">

        <!--<a *ngFor="let item of menuItems" class="dropdown-item" [attr.href]="item.href" [routerLink]="item.routerLink" [target]="item.target" [state]="item.state" >{{ item.title }}</a>-->
        <a class="dropdown-item" [routerLink]='["/user/settings"]'>User Settings</a>
        <a class="dropdown-item" href="https://support.smartcitymetro.com/" target="_blank">Support</a>
        <a class="dropdown-item" [routerLink]='["/authentication/logout"]' [state]='{ local: true }'>Logout</a>
      </div>
    </li>
  </ul>
</div>

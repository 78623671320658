import { Injectable } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { retry, catchError } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { RemoveSsoCommandResult, UpdateEmailResult, UpdateNameResult, UpdatePasswordResult, UserSettingsQueryResult } from './modules/user/pages/user-settings/user-settings.component';
import swal from 'sweetalert2';
import { SiteSettingsQueryResult } from './app.component';
import { LogService } from './core/services/log.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected baseUrl: string;
  protected httpClient: HttpClient

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(_httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, protected logService: LogService) {
    this.httpClient = _httpClient;
    this.baseUrl = _baseUrl
  }

  // POST
  UpdatePassword(data): Observable<ApiResponse<UpdatePasswordResult>> {
    this.logService.debug("UpdatePassword", data);
    return this.httpClient.post<ApiResponse<UpdatePasswordResult>>(this.baseUrl + 'user/password/update', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  SetInitialPassword(data): Observable<ApiResponse<UpdatePasswordResult>> {
    this.logService.debug("SetInitialPassword", data);
    return this.httpClient.post<ApiResponse<UpdatePasswordResult>>(this.baseUrl + 'user/password/setinitial', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  // POST
  UpdateName(data): Observable<ApiResponse<UpdateNameResult>> {
    this.logService.debug("UpdateName", data);
    return this.httpClient.post<ApiResponse<UpdateNameResult>>(this.baseUrl + 'user/name/update', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  // POST
  UpdateEmail(data): Observable<ApiResponse<UpdateEmailResult>> {
    this.logService.debug("UpdateEmail", data);
    return this.httpClient.post<ApiResponse<UpdateEmailResult>>(this.baseUrl + 'user/update/email', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  // POST
  UpdateSecurityQuestion(data): Observable<ApiResponse<ActionResult>> {
    this.logService.debug("UpdateSecurityQuestion", data);
    return this.httpClient.post<ApiResponse<ActionResult>>(this.baseUrl + 'user/securityquestion/update', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  // POST
  GetUserSettings(data): Observable<ApiResponse<UserSettingsQueryResult>> {
    this.logService.debug("GetUserSettings", data);
    return this.httpClient.post<ApiResponse<UserSettingsQueryResult>>(this.baseUrl + 'user/settings', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  // POST
  GetSiteSettings(): Observable<ApiResponse<SiteSettingsQueryResult>> {
    this.logService.debug("GetUserSettings");
    return this.httpClient.get<ApiResponse<SiteSettingsQueryResult>>(this.baseUrl + 'api/site/settings', this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  RemoveSso(data): Observable<ApiResponse<RemoveSsoCommandResult>> {
    this.logService.debug("RemoveSso");
    return this.httpClient.post<ApiResponse<RemoveSsoCommandResult>>(this.baseUrl + 'user/removesso', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    swal.fire({
      title: 'Error Occurred!',
      type: 'warning'
    });
    this.logService.error("Api Error", errorMessage);
    return throwError(errorMessage);
  }
}

export interface ApiResponse<T>{
  Status: number,
  HttpStatus: number,
  Message: string,
  Result: T,
  Exception: object
}

export interface ActionResult {
  Success: boolean,
  Errors: string[]
}

import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { PaymentModule } from './modules/payment/payment.module';
import { AccountModule } from './modules/account/account.module';
import { UserModule } from './modules/user/user-module';
import { HomeModule } from './modules/home/home.module';

import { ApiAuthorizationModule } from '../api-authorization/api-authorization.module';
import { AuthorizeGuard } from '../api-authorization/authorize.guard';
import { AuthorizeInterceptor } from '../api-authorization/authorize.interceptor';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './core/theme.module';
import * as $ from 'jquery';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'ngx-moment'; // optional, provides moment-style pipes for date formatting

import { ModalModule } from 'ngx-bootstrap/modal';
import { LogService } from './core/services/log.service';
import { LogPublisherService } from './core/services/logpublisher.service';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AppSettingsService } from './core/services/appsettings.service';
import { NgxMatomoTrackerModule } from 'ngx-matomo-client';
import { NgxMatomoRouterModule } from 'ngx-matomo-client';
import {CurrencyPipe} from "@angular/common";


const modules = [
  PaymentModule,
  AccountModule,
  HomeModule,
  UserModule
]

export function appInit(appsettingsSerivce: AppSettingsService) {
  return () => appsettingsSerivce.load();
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ApiAuthorizationModule,
    CoreModule,
    ThemeModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ModalModule.forRoot(),
    // BaseChartDirective,
    NgbModule,
    NgbTooltipModule,
    // NgxMatomoTrackerModule.forRoot({
    //   siteId: '23', // your Matomo's site ID (find it in your Matomo's settings)
    //   trackerUrl: 'https://analytics.smartcitynetworks.com/', // your matomo server root url
    // }),
    // NgxMatomoRouterModule,
    // ...modules,
    // NgxMatomoTrackerModule.forRoot({ trackerUrl: 'https://analytics.smartcitynetworks.com/matomo.php', siteId: '23', scriptUrl: 'https://analytics.smartcitynetworks.com/matomo.js' })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    LogService,
    LogPublisherService,
    AppSettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppSettingsService]
    },
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import {Component, Inject, ViewChild, ElementRef, OnInit} from '@angular/core';
import { ActionResult } from '../../../../api.service';
import { TitleService } from '../../../../core/services/title.service';
import { AccountService } from '../../account-service';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../../../payment/payment.service';
import {  MatSlideToggleChange } from '@angular/material/slide-toggle';
import _resourcedata from '../../../../../assets/resources/resource.json';
import { NotificationService } from '../../../../core/services/notification.service';
import swal from 'sweetalert2';
import { LogService } from '../../../../core/services/log.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-account-detail',
    templateUrl: './account-detail.component.html',
    styleUrls: ['./account-detail.component.scss']
})
/** account-detail component*/
export class AccountDetailComponent implements OnInit{
/** account-detail ctor */

  @ViewChild('redirectPostForm', { static: true }) public redirectPostForm: ElementRef;
  @ViewChild('submitButton', { static: true }) public submitButton: ElementRef;
  @ViewChild('BroadBandIframe', { static: false }) public iframe: ElementRef;

  public accountDetail: AccountDetailQueryResult;

  private disableEBillingOptOut: boolean = true;
  private disableEBillingOptOutPressed: boolean = false;

  private disableEBillingButton: boolean = false;
  private pendingEBillingButton: boolean = false;
  private apiService: AccountService;
  private titleService: TitleService;
  private toastrService: ToastrService;
  private logService: LogService;
  private resource: object;
  private notificationService: NotificationService;

  services: BroadBandService[] = [];
  iframeUrls: { planIdentifier: string, member: string | null, iframeUrl: any }[] = [];

  constructor(_apiService: AccountService, _titleService: TitleService, _toastrService: ToastrService, _notificationService: NotificationService, _logService: LogService, private sanitizer: DomSanitizer) {
    this.apiService = _apiService;
    this.titleService = _titleService;
    this.toastrService = _toastrService;
    this.titleService.setTitle("Billing Account Details");
    this.resource = _resourcedata.BillingAccountDetail;
    this.notificationService = _notificationService;
    this.logService = _logService;

    const accountDetailPost: AccountDetailPost = { CustomerId: localStorage.getItem("AccountKey") };
    this.logService.info("GetAccountDetails", "Start");
    this.apiService.GetAccountDetails(accountDetailPost).subscribe(res => {
      this.logService.debug("GetAccountDetails", accountDetailPost, res);
      console.log("ACCOUNT DETAILS: " + JSON.stringify(res));
      this.accountDetail = res.Result;
      this.disableEBillingButton = this.accountDetail.PendingEBillingChange;
      this.pendingEBillingButton = this.accountDetail.PendingEBillingChange;
    }, error => {
        this.logService.error("GetAccountDetails", error, accountDetailPost);
    });



  }

  ngOnInit() {
    this.populateBroadBandFacts();
  }

  populateBroadBandFacts() {
    this.apiService.GetBroadBandFacts(localStorage.getItem("AccountKey")).subscribe(res => {
      this.services = res.Result.Services;
      // Extract unique PlanIdentifiers and their corresponding Members
      const uniquePlans = new Map<string, BroadBandService>();
      this.services.forEach(service => {
        if (service.PlanIdentifier && !uniquePlans.has(service.PlanIdentifier)) {
          uniquePlans.set(service.PlanIdentifier, service);
        }
      });

      this.iframeUrls = Array.from(uniquePlans.values()).map(service => ({
        planIdentifier: service.PlanIdentifier,
        member: service.Member,
        iframeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(`https://broadbandfacts-test.smartcitytelecom.com/fact/${service.PlanIdentifier}`)
      }));
    }, error => {
      this.logService.error("GetBroadBandFacts", error, this.accountDetail.Account);
    });
  }
  toggleBilling(event: MatSlideToggleChange) {
    //Incoming Uncheck = OptOut
    if (!event.checked && this.disableEBillingOptOut) {
      event.source.checked = !(event.checked);
      this.disableEBillingButton = true;
      this.disableEBillingOptOutPressed = true;
    }
    else {
      //Incoming Checked = OptIn, Unchecked = OptOut
      console.info("ebilling status" + event.checked);
      var text = event.checked ? this.resource["BillingPreferences"]["EBillingOptInConfirmation"] : this.resource["BillingPreferences"]["EBillingOptOutConfirmation"];
      swal.fire({
        title: 'Confirm',
        text: text,
        type: 'warning',
        customClass: {
          content: "text-justify"
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          const model: UpdateEBillingPreferenceCommand = { CustomerId: this.accountDetail.CustomerId, EnableEBilling: event.checked };

          this.apiService.UpdateEBilling(model).subscribe(res => {
            if (res.Result.Success) {
              this.toastrService.success("E-Billing updated", null, { positionClass: 'toast-bottom-center' });
              this.accountDetail.EBillingEnabled = !this.accountDetail.EBillingEnabled;
              this.disableEBillingButton = true;
              this.pendingEBillingButton = true;
            } else {
              this.toastrService.error("E-Billing failed to update", null, { positionClass: 'toast-bottom-center' });
            }
          }, error => { });
        } else {
          event.source.checked = !(event.checked);
        }
      });
    }
  }

  toggleMarketing(event: MatSlideToggleChange) {
    //Checked = OptIn, Unchecked = OptOut
    var text = event.checked ? this.resource["BillingPreferences"]["MarketingOptInConfirmation"] : this.resource["BillingPreferences"]["MarketingOptOutConfirmation"];
    swal.fire({
      title: 'Confirm',
      text: text,
      type: 'warning',
      customClass: {
        content: "text-justify"
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        const model: UpdateMarketingPreferenceCommand = { CustomerId: this.accountDetail.CustomerId, MarketingOptOut: !event.checked };

        this.apiService.UpdateMarketing(model).subscribe(res => {
          if (res.Result.Success) {
            this.toastrService.success("Marketing updated", null, { positionClass: 'toast-bottom-center' });
            this.accountDetail.MarketingOptOut = !this.accountDetail.MarketingOptOut;
          } else {
            this.toastrService.error("Marketing failed to update", null, { positionClass: 'toast-bottom-center' });
          }
        }, error => { });
      } else {
        event.source.checked = !(event.checked);
      }
    });
  }
}

export interface BillHistoryEntity {
  Id: number;
  BillHistId: number;
  BillId?: number;
  Updated?: Date;
  BillDateFrom?: Date;
  BillDateTo?: Date;
  BillType: string;
  BillAllowed?: number;
  BillUsed?: number;
  BillOveruse?: number;
  BillPercent?: number;
  Rate95thIn?: number;
  Rate95thOut?: number;
  Rate95th?: number;
  Dir95th?: string;
  RateAverage?: number;
  RateAverageIn?: number;
  RateAverageOut?: number;
  TrafIn?: number;
  TrafOut?: number;
  TrafTotal?: number;
  Pdf?: string;
}
export interface UpdateEBillingPreferenceCommand {
  EnableEBilling?: boolean;
  CustomerId: string;
}

export interface UpdateEBillingPreferenceResponse extends ActionResult {
  EnableEBilling: boolean;
}

export interface UpdateMarketingPreferenceCommand {
  MarketingOptOut?: boolean;
  CustomerId: string;
}

export interface UpdateMarketingPreferenceResponse extends ActionResult {
  MarketingOptOut: boolean;
}

export interface AccountDetailPost {
  CustomerId: string
}

export interface AccountDetailQueryResult extends ActionResult {
  Account: string;
  Username: string;
  LastPasswordUpdateDate: Date;
  FirstName: string;
  LastName: string;
  Email: string;
  EBillingEnabled: boolean;
  PendingEBillingChange: boolean;
  MarketingOptOut;
  CustomerId: string;
}
export interface BroadBandServicesQueryResult extends ActionResult {
  Services: BroadBandService[];
}
export interface BroadBandService extends ActionResult {
  id: number;
  CustomerId?: number | null;
  Member?: string | null;
  SeCode?: string | null;
  PlanIdentifier?: string | null;
  ConnectDate?: Date | null;
  DisconnectDate?: Date | null;
  CreateDate?: Date | null;
  ContractTerm?: string | null;
  Rate?: string | null;
}
export interface BroadBandFactsQueryResult extends ActionResult {
  id: number;
  uniquePlanId?: string;
  providerName?: string;
  servicePlanName?: string;
  tierPlanName?: string;
  connectionType?: string;
  monthlyPrice?: number;
  introRate?: string;
  introRatePrice?: number | null;
  introRateTime?: number | null;
  contractReq?: string;
  contractTime?: number | null;
  contractTermUrl?: string;
  earlyTerminationFee?: string;
  governmentTax?: string;
  bundleDiscountsUrl?: string;
  download?: number;
  upload?: number;
  latency?: number;
  monthlyDataAllow?: number;
  overUsageDataPrice?: number;
  additionalDataIncrement?: number | null;
  dataAllowancePolicyUrl?: string;
  networkManagementPolicyUrl?: string;
  privacyPolicyUrl?: string;
  customerSupportPhone?: string;
  customerSupportWeb?: string;
  customerSupportEmail?: string;
  active?: string;
  archiveDate?: Date | null;
  createDate?: Date;
}

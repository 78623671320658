import { Component } from '@angular/core';

@Component({
    selector: 'header-cmp',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
/** header component*/
export class HeaderComponent {
    /** header ctor */
    constructor() {

    }
}

<div class="main-content">
  <div class="row" *ngIf="!userSettingsQueryResult">
    <mat-spinner color="accent" class="m-auto"></mat-spinner>
  </div>
  <div class="row" *ngIf="userSettingsQueryResult">
    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.Password.Title}}</h4>
              </div>
            </div>

            <div class="col-sm-5">
              <div class="pull-right">
                <h5 class="btn btn-outline-primary btn-round cursorPointer font-size-15 m-0 pt-1 pb-1 pl-3 pr-3" (click)="showUpdatePassword()">{{ showUpdatePasswordVariable ? "Cancel": "Edit" }}</h5>
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <p class="mt-2 mb-2 header-type-3">{{resource.Password.Line1}} <span>{{ userSettingsQueryResult.PasswordLastUpdated | date : 'longDate' }}</span></p>
          <form #f="ngForm" novalidate (ngSubmit)="updatePasswordFunction(f.value, f.valid)" *ngIf='showUpdatePasswordVariable;'>
            <div *ngIf="this.userSettingsQueryResult.PasswordSet" class="form-group has-label">
              <label for="" class="control-label">
                Old Password
                <span class="star">*</span>
              </label>
              <input type="password" class="form-control" name="OldPassword" [ngModel]="updatePassword.OldPassword"
                     required #OldPassword="ngModel">
              <small [hidden]="OldPassword.valid || (OldPassword.pristine && !f.submitted)" class="text-danger">
                Old Password is required
              </small>
            </div>
            <div class="form-group has-label">
              <label for="" class="control-label">
                Password
                <span class="star">*</span>
              </label>
              <input type="password" class="form-control" name="NewPassword" [ngModel]="updatePassword.NewPassword"
                     required validateEqual="ConfirmPassword" reverse="true" #NewPassword="ngModel">
              <small [hidden]="NewPassword.valid || (NewPassword.pristine && !f.submitted)" class="text-danger">
                Password is required
              </small>
            </div>
            <div class="form-group has-label">
              <label for="" class="control-label">
                Confirm Password
                <span class="star">*</span>
              </label>

              <input type="password" class="form-control" name="ConfirmPassword" [ngModel]="updatePassword.ConfirmPassword"
                     required validateEqual="NewPassword" reverse="false" #ConfirmPassword="ngModel">
              <small [hidden]="ConfirmPassword.valid || (ConfirmPassword.pristine && !f.submitted)" class="text-danger">
                Confirm Password mismatch
              </small>
            </div>
            <div class="category">    <span class="star">*</span> Required fields</div>
            <button type="submit" [disabled]="disableUpdatePasswordButton" class="btn btn-primary">Update Password</button>
          </form>
        </div>
      </div>
    </div>

    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.PersonalInformation.Title}}</h4>
              </div>
            </div>

            <div class="col-sm-5">
              <div class="pull-right">
                <h5 class="btn btn-outline-primary btn-round cursorPointer font-size-15 m-0 pt-1 pb-1 pl-3 pr-3" (click)="showUpdateName()">{{ showUpdateNameVariable ? "Cancel": "Edit" }}</h5>
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <p class="mt-2 mb-2 header-type-3">{{resource.PersonalInformation.Line1}} <span>{{ userSettingsQueryResult.FirstName }} {{ userSettingsQueryResult.LastName }} </span></p>
          <form #f2="ngForm" novalidate (ngSubmit)="updateName(f2.value, f2.valid)" *ngIf='showUpdateNameVariable;'>
            <div class="form-group has-label">
              <label for="" class="control-label">
                First Name
                <span class="star">*</span>
              </label>
              <input type="text" class="form-control" name="FirstName" [ngModel]="userSettingsQueryResult.FirstName"
                     required #FirstName="ngModel">
              <small [hidden]="FirstName.valid || (FirstName.pristine && !f2.submitted)" class="text-danger">
                First Name is required
              </small>
            </div>
            <div class="form-group has-label">
              <label for="" class="control-label">
                Last Name
                <span class="star">*</span>
              </label>
              <input type="text" class="form-control" name="LastName" [ngModel]="userSettingsQueryResult.LastName"
                     required #LastName="ngModel">
              <small [hidden]="LastName.valid || (LastName.pristine && !f2.submitted)" class="text-danger">
                Last Name is required
              </small>
            </div>
            <div class="category">    <span class="star">*</span> Required fields</div>
            <button type="submit" [disabled]="disableUpdateNameButton" class="btn btn-primary">Update Name</button>
          </form>
        </div>
      </div>
    </div>

    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.Email.Title}}</h4>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right" *ngIf="!isSsoEnabled()">
                <h5 class="btn btn-outline-primary btn-round cursorPointer font-size-15 m-0 pt-1 pb-1 pl-3 pr-3" (click)="showUpdateEmail()">{{ showUpdateEmailVariable ? "Cancel": "Edit" }}</h5>
              </div>
              <div class="pull-right" *ngIf="isSsoEnabled()">
                <small class="text-danger">
                  {{resource.Email.RemoveSsoWarning}}
                </small>
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">

          <p class="mt-2 mb-2 header-type-3" *ngIf='!showUpdateEmailVariable'>
            {{resource.Email.Line1}} <span>{{ userSettingsQueryResult.Email }}</span>
          </p>
          <p *ngIf="!showUpdateEmailVariable && userSettingsQueryResult.PendingEmail && userSettingsQueryResult.PendingEmail != userSettingsQueryResult.Email">
            <span class="text-danger">Pending change to {{ userSettingsQueryResult.PendingEmail }} </span><br />
            <span class="btn btn-primary btn-sm" (click)="resendEmailConfirm()">Resend Email Confirmation</span>
          </p>

          <form #f3="ngForm" novalidate (ngSubmit)="updateEmailFunction(f3.value, f3.valid)" *ngIf='showUpdateEmailVariable;'>
            <div class="form-group has-label">
              <label for="" class="control-label">
                New Email
                <span class="star">*</span>
              </label>
              <input type="text" class="form-control" name="Email" [ngModel]="updateEmail.Email"
                     required validateEqual="ConfirmEmail" reverse="true" #Email="ngModel">
              <small [hidden]="Email.valid || (Email.pristine && !f3.submitted)" class="text-danger">
                Email is required
              </small>
            </div>
            <div class="form-group has-label">
              <label for="" class="control-label">
                Confirm Email
                <span class="star">*</span>
              </label>
              <input type="text" class="form-control" name="ConfirmEmail" [ngModel]="updateEmail.ConfirmEmail"
                     required validateEqual="Email" reverse="false" #ConfirmEmail="ngModel">
              <small [hidden]="ConfirmEmail.valid || (ConfirmEmail.pristine && !f3.submitted)" class="text-danger">
                Confirm Email is required
              </small>
            </div>
            <div class="category">    <span class="star">*</span> Required fields</div>
            <button type="submit" [disabled]="disableUpdateEmailButton || isSsoEnabled()" class="btn btn-primary">Update Email</button>
            <small *ngIf="isSsoEnabled()" class="text-danger">
              {{resource.Email.RemoveSsoWarning}}
            </small>
          </form>
        </div>
      </div>
    </div>

    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.FirstSecurityQuestion.Title}} </h4>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">
                <h5 class="btn btn-outline-primary btn-round cursorPointer font-size-15 m-0 pt-1 pb-1 pl-3 pr-3" (click)="showUpdateSecurityQuestion(0)">{{ showUpdateSecurityQuestionsVariable[0] ? "Cancel": "Edit" }}</h5>
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <p *ngIf='!showUpdateSecurityQuestionsVariable[0];'>{{userSettingsQueryResult.SecurityQuestions[0].Question}}</p>
          <form #f4="ngForm" novalidate (ngSubmit)="updateSecurityQuestion(0, f4.value, f4.valid)" *ngIf='showUpdateSecurityQuestionsVariable[0];'>
            <div class="form-group has-label">
              <label for="" class="control-label">
                {{resource.SelectSecurityQuestion}}
                <span class="star">*</span>
              </label>
              <select class="form-control" name="Question" [ngModel]="userSettingsQueryResult.SecurityQuestions[0].Question" required #Question="ngModel">
                <option *ngFor="let securityQuestion of userSettingsQueryResult.AvailableQuestions" [value]="securityQuestion">{{securityQuestion}}</option>
              </select>
              <small [hidden]="Question.valid || (Question.pristine && !f4.submitted)" class="text-danger">
                Security question is required
              </small>
            </div>
            <div class="form-group has-label">
              <label for="" class="control-label">
                {{resource.AnswerSecurityQuestion}}
                <span class="star">*</span>
              </label>
              <input type="text" class="form-control" name="Answer" [ngModel]="userSettingsQueryResult.SecurityQuestions[0].Answer"
                     required #Answer="ngModel" pattern="[a-zA-Z0-9\s]{3,}">
              <small [hidden]="Answer.valid || (Answer.pristine && !f4.submitted)" class="text-danger">
                Security answer is required
              </small>
              <small *ngIf="Answer.errors?.pattern" class="text-danger">
                Security answer must be a minimum of three (3) characters without any special characters. Alphanumeric and spaces only.
              </small>
            </div>

            <div class="category">    <span class="star">*</span> Required fields</div>
            <button type="submit" [disabled]="disableUpdateSecurityQuestionButton[0]" class="btn btn-primary">Update Security Question</button>
          </form>
        </div>
      </div>
    </div>

    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.SecondSecurityQuestion.Title}} </h4>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">
                <h5 class="btn btn-outline-primary btn-round cursorPointer font-size-15 m-0 pt-1 pb-1 pl-3 pr-3" (click)="showUpdateSecurityQuestion(1)">{{ showUpdateSecurityQuestionsVariable[1] ? "Cancel": "Edit" }}</h5>
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <p *ngIf='!showUpdateSecurityQuestionsVariable[1];'>{{userSettingsQueryResult.SecurityQuestions[1].Question}}</p>
          <form #f5="ngForm" novalidate (ngSubmit)="updateSecurityQuestion(1, f5.value, f5.valid)" *ngIf='showUpdateSecurityQuestionsVariable[1];'>
            <div class="form-group has-label">
              <label for="" class="control-label">
                {{resource.SelectSecurityQuestion}}
                <span class="star">*</span>
              </label>
              <select class="form-control" name="Question" [ngModel]="userSettingsQueryResult.SecurityQuestions[1].Question" required #Question="ngModel">
                <option *ngFor="let securityQuestion of userSettingsQueryResult.AvailableQuestions" [value]="securityQuestion">{{securityQuestion}}</option>
              </select>
              <small [hidden]="Question.valid || (Question.pristine && !f5.submitted)" class="text-danger">
                Security question is required
              </small>
            </div>
            <div class="form-group has-label">
              <label for="" class="control-label">
                {{resource.AnswerSecurityQuestion}}
                <span class="star">*</span>
              </label>
              <input type="text" class="form-control" name="Answer" [ngModel]="userSettingsQueryResult.SecurityQuestions[1].Answer"
                     required #Answer="ngModel" pattern="[a-zA-Z0-9\s]{3,}">
              <small [hidden]="Answer.valid || (Answer.pristine && !f5.submitted)" class="text-danger">
                Security answer is required
              </small>
              <small *ngIf="Answer.errors?.pattern" class="text-danger">
                Security answer must be a minimum of three (3) characters without any special characters. Alphanumeric and spaces only.
              </small>
            </div>

            <div class="category">    <span class="star">*</span> Required fields</div>
            <button type="submit" [disabled]="disableUpdateSecurityQuestionButton[1]" class="btn btn-primary">Update Security Question</button>
          </form>
        </div>
      </div>
    </div>

    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.ThirdSecurityQuestion.Title}} </h4>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">
                <h5 class="btn btn-outline-primary btn-round cursorPointer font-size-15 m-0 pt-1 pb-1 pl-3 pr-3" (click)="showUpdateSecurityQuestion(2)">{{ showUpdateSecurityQuestionsVariable[2] ? "Cancel": "Edit" }}</h5>
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <p *ngIf='!showUpdateSecurityQuestionsVariable[2];'>{{userSettingsQueryResult.SecurityQuestions[2].Question}}</p>
          <form #f6="ngForm" novalidate (ngSubmit)="updateSecurityQuestion(2, f6.value, f6.valid)" *ngIf='showUpdateSecurityQuestionsVariable[2];'>
            <div class="form-group has-label">
              <label for="" class="control-label">
                {{resource.SelectSecurityQuestion}}
                <span class="star">*</span>
              </label>
              <select class="form-control" name="Question" [ngModel]="userSettingsQueryResult.SecurityQuestions[2].Question" required #Question="ngModel">
                <option *ngFor="let securityQuestion of userSettingsQueryResult.AvailableQuestions" [value]="securityQuestion">{{securityQuestion}}</option>
              </select>
              <small [hidden]="Question.valid || (Question.pristine && !f6.submitted)" class="text-danger">
                Security question is required
              </small>
            </div>
            <div class="form-group has-label">
              <label for="" class="control-label">
                {{resource.AnswerSecurityQuestion}}
                <span class="star">*</span>
              </label>
              <input type="text" class="form-control" name="Answer" [ngModel]="userSettingsQueryResult.SecurityQuestions[2].Answer"
                     required #Answer="ngModel" pattern="[a-zA-Z0-9\s]{3,}">
              <small [hidden]="Answer.valid || (Answer.pristine && !f6.submitted)" class="text-danger">
                Security answer is required
              </small>
              <small *ngIf="Answer.errors?.pattern" class="text-danger">
                Security answer must be a minimum of three (3) characters without any special characters. Alphanumeric and spaces only.
              </small>
            </div>

            <div class="category">    <span class="star">*</span> Required fields</div>
            <button type="submit" [disabled]="disableUpdateSecurityQuestionButton[2]" class="btn btn-primary">Update Security Question</button>
          </form>
        </div>
      </div>
    </div>

    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.Sso.Title}}</h4>
              </div>
            </div>
            <div *ngIf="isSsoEnabled()" class="col-sm-5">
              <div class="pull-right">
                <h5 class="btn btn-outline-primary btn-round cursorPointer font-size-15 m-0 pt-1 pb-1 pl-3 pr-3" (click)="showUpdateSso()">{{ showUpdateSsoVariable ? "Cancel": "Edit" }}</h5>
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">

          <p class="mt-2 mb-2 header-type-3" *ngIf='!showUpdateSsoVariable'>
            <span *ngIf="isSsoEnabled()">{{resource.Sso.Enabled}}</span>
            <span *ngIf="!isSsoEnabled()">{{resource.Sso.NotEnabled}}</span>
          </p>

          <form #f4="ngForm" novalidate (ngSubmit)="removeSsoLogin()" *ngIf='showUpdateSsoVariable;'>
            <button type="submit" [disabled]="disableUpdateSsoButton" class="btn btn-primary">Remove Single Sign-on</button>
            <small *ngIf="showNoPasswordSetWarning" class="text-danger">
              {{resource.Sso.NoPasswordSetWarning}}
            </small>
          </form>

        </div>
      </div>
    </div>

  </div>
</div>

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from '../../../../core/services/title.service';

@Component({
    selector: 'app-paynow-callback',
    templateUrl: './paynow-callback.component.html',
    styleUrls: ['./paynow-callback.component.scss']
})
/** paynow-callback component*/
export class PaynowCallbackComponent {
    /** paynow-callback ctor */
    response: string;

  constructor(private route: ActivatedRoute,
    private titleService: TitleService) {
    this.titleService.setTitle("Make A Payment");
      this.route.queryParams.subscribe(params => {
        this.response = params['response'];
      });
    }
}

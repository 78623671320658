<header>
  <div class="row">
    <div class="col">
      <div class="header-logo">
        <img src="./assets/img/SmartCityLogoHorizontal.png">
      </div>
    </div>
    <app-login-menu class="col">

    </app-login-menu>
  </div>
</header>

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { APP_BASE_HREF, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EqualValidator } from '../../core/equal-validator.directive';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    UserRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressSpinnerModule
  ],
  declarations:
    [UserRoutingModule.components,

      EqualValidator],
  bootstrap: [],
  providers: [CurrencyPipe]
})

export class UserModule { }

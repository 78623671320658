import { Component, Inject, ViewChildren, ElementRef } from '@angular/core';
import { ActionResult } from '../../../../api.service';
import { TitleService } from '../../../../core/services/title.service';
import { AccountService } from '../../../account/account-service';
import { ToastrService } from 'ngx-toastr';
import _resourcedata from '../../../../../assets/resources/resource.json';
import { LogService } from '../../../../core/services/log.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
/** account-detail component*/
export class UserSettingsComponent {
  /** account-detail ctor */
  public showUpdatePasswordVariable: boolean = false;
  public showUpdateEmailVariable: boolean = false;
  public showUpdateNameVariable: boolean = false;
  public showUpdateSecurityQuestionsVariable: boolean[] = [false, false, false];
  public showUpdateSsoVariable: boolean = false;
  public showNoPasswordSetWarning: boolean = false;
  public disableUpdatePasswordButton: boolean = false;
  public disableUpdateEmailButton: boolean = false;
  public disableUpdateNameButton: boolean = false;
  public disableUpdateSecurityQuestionButton: boolean[] = [false, false, false];
  public disableUpdateSsoButton: boolean = false;


  @ViewChildren('OldPassword') firstInputUpdatePassword;
  @ViewChildren('f2') firstInputUpdateName;
  @ViewChildren('f3') firstInputUpdateEmail;

  public updatePassword: UpdatePasswordCommand;
  public updateEmail: UpdateEmailCommand;
  public userSettings: AccountDetailQueryResult;
  private apiService: AccountService;
  private titleService: TitleService;
  private toastrService: ToastrService;
  private logService: LogService;
  private resource: object;

  public userSettingsQueryResult: UserSettingsQueryResult;

  constructor(_apiService: AccountService, _titleService: TitleService, _toastService: ToastrService, _logService: LogService) {
    this.apiService = _apiService;
    this.titleService = _titleService;
    this.toastrService = _toastService;
    this.titleService.setTitle("User Settings");
    this.logService = _logService;
    this.resource = _resourcedata.UserSettings;

    this.updatePassword = {
      OldPassword: '',
      NewPassword: '',
      ConfirmPassword: ''
    }
    this.updateEmail = {
      Email: '',
      ConfirmEmail: ''
    };

    const availableSecurityQuestionListQuery: UserSettingsQuery = {};
    this.logService.info("GetUserSettings", "Start");
    this.apiService.GetUserSettings(availableSecurityQuestionListQuery).subscribe(res => {
      this.logService.info("GetUserSettings", res);
      for (var i = 0; i <= 2; i++) {
        if (res.Result.SecurityQuestions.length == i) {
          const emptySecurityQuestion: UserSettingsQuestionItem = { Answer: "", Index: i, Question: "" };
          res.Result.SecurityQuestions[i] = emptySecurityQuestion;
        }
      }
      this.userSettingsQueryResult = res.Result;
    }, error => this.logService.error("GetUserSettings", error, availableSecurityQuestionListQuery));
  }

  showUpdatePassword() {
    if (this.showUpdatePasswordVariable) {
      this.showUpdatePasswordVariable = false;
    } else {
      this.showUpdatePasswordVariable = true;
      this.firstInputUpdatePassword.nativeElement.focus();
    }
  }

  showUpdateSecurityQuestion(questionIndex: number) {
    if (this.showUpdateSecurityQuestionsVariable[questionIndex]) {
      this.showUpdateSecurityQuestionsVariable[questionIndex] = false;
    } else {
      this.showUpdateSecurityQuestionsVariable[questionIndex] = true;
    }
  }

  showUpdateEmail() {
    if (this.showUpdateEmailVariable) {
      this.showUpdateEmailVariable = false;
    } else {
      this.showUpdateEmailVariable = true;
    }
  }

  showUpdateName() {
    if (this.showUpdateNameVariable) {
      this.showUpdateNameVariable = false;
    } else {
      this.showUpdateNameVariable = true;
    }
  }

  showUpdateSso() {
    if (this.showUpdateSsoVariable) {
      this.showUpdateSsoVariable = false;
    } else {
      this.showUpdateSsoVariable = true;
    }
  }

  updatePasswordFunction(model: UpdatePasswordCommand, isValid: boolean) {
    if (isValid && model.NewPassword == model.ConfirmPassword) {
      this.disableUpdatePasswordButton = true;
      if (this.userSettingsQueryResult.PasswordSet) {
        this.apiService.UpdatePassword(model).subscribe(res => {
          if (res.Result.Success) {
            this.toastrService.success("Password Updated", null, { positionClass: 'toast-bottom-center' });
            this.userSettingsQueryResult.PasswordLastUpdated = res.Result.PasswordLastUpdated;
            this.showNoPasswordSetWarning = false;
            this.userSettingsQueryResult.PasswordSet = true;
          } else {
            this.toastrService.error("Password failed to update", null, { positionClass: 'toast-bottom-center' });
          }
          this.disableUpdatePasswordButton = false;
          this.showUpdatePassword();

        }, error => {
          this.disableUpdatePasswordButton = false
        });
      }
      else
      {
        this.apiService.SetInitialPassword(model).subscribe(res => {
          if (res.Result.Success) {
            this.toastrService.success("Password Updated", null, { positionClass: 'toast-bottom-center' });
            this.userSettingsQueryResult.PasswordLastUpdated = res.Result.PasswordLastUpdated;
            this.showNoPasswordSetWarning = false;
            this.userSettingsQueryResult.PasswordSet = true;
          } else {
            this.toastrService.error("Password failed to update", null, { positionClass: 'toast-bottom-center' });
          }
          this.disableUpdatePasswordButton = false;
          this.showUpdatePassword();

        }, error => {
          this.disableUpdatePasswordButton = false
        });
      }

    } else {
      return false;
    }
  }

  updateEmailFunction(model: UpdateEmailCommand, isValid: boolean) {
    if (isValid && model.Email == model.ConfirmEmail) {
      this.disableUpdateEmailButton = true;

      this.logService.info("UpdateEmail", "Start");
      this.apiService.UpdateEmail(model).subscribe(res => {
        this.logService.debug("UpdateEmail", model, res);
        if (res.Result.Success) {
          this.userSettingsQueryResult.PendingEmail = res.Result.Email;
          this.showUpdateEmail();
          this.toastrService.success("Email Updated", null, { positionClass: 'toast-bottom-center' });
        } else {
          this.toastrService.error("Email failed to update", null, { positionClass: 'toast-bottom-center' });
        }
        this.disableUpdateEmailButton = false;
      }, error => {
          this.logService.error("UpdateEmail", error);
        this.disableUpdateEmailButton = false
      });

    } else {
      return false;
    }
  }

  resendEmailConfirm() {
    if (this.userSettingsQueryResult.PendingEmail) {
      var model = {
        Email: this.userSettingsQueryResult.PendingEmail
      };
      this.apiService.UpdateEmail(model).subscribe(res => {
        if (res.Result.Success) {
          this.userSettingsQueryResult.PendingEmail = res.Result.Email;
          this.toastrService.success("Email Confirmation Sent", null, { positionClass: 'toast-bottom-center' });
        } else {
          this.toastrService.error("Email Confirmation failed to send", null, { positionClass: 'toast-bottom-center' });
        }
      }, error => {
      });
    }
  }

  updateName(model: UpdatePasswordCommand, isValid: boolean) {
    if (isValid) {
      this.disableUpdateNameButton = true;

      this.logService.info("UpdateName", "Start");
      this.apiService.UpdateName(model).subscribe(res => {
        this.logService.debug("UpdateName", model, res);
        if (res.Result.Success) {
          this.userSettingsQueryResult.FirstName = res.Result.FirstName;
          this.userSettingsQueryResult.LastName = res.Result.LastName;
          this.showUpdateName();
          this.toastrService.success("Name Updated", null, { positionClass: 'toast-bottom-center' });
        } else {
          this.toastrService.error("Name failed to update", null, { positionClass: 'toast-bottom-center' });
        }
        this.disableUpdateNameButton = false;
      }, error =>
        {
          this.disableUpdateNameButton = false
          this.logService.error("UpdateName", error, model);
        }
      );

    } else {
      return false;
    }
  }

  updateSecurityQuestion(questionIndex: number, model: UserSettingsQuestionItem, isValid: boolean) {
    if (isValid) {
      this.disableUpdateSecurityQuestionButton[questionIndex] = true;
      model.Index = questionIndex;
      this.logService.info("UpdateSecurityQuestion", "Start")
      this.apiService.UpdateSecurityQuestion(model).subscribe(res => {
        this.logService.debug("UpdateSecurityQuestion", res, model);
        if (res.Result.Success) {
          this.showUpdateSecurityQuestion(questionIndex);
          this.userSettingsQueryResult.SecurityQuestions[questionIndex].Question = model.Question;
          this.userSettingsQueryResult.SecurityQuestions[questionIndex].Answer = "";
          this.toastrService.success("Security question updated", null, { positionClass: 'toast-bottom-center' });
        } else {
          this.toastrService.error("Security question failed to update", null, { positionClass: 'toast-bottom-center' });
        }
        this.disableUpdateSecurityQuestionButton[questionIndex] = false;
      }, error => {
          this.disableUpdateSecurityQuestionButton[questionIndex] = false
          this.logService.error("UpdateSecurityQuestion", error);
      });

    } else {
      return false;
    }
  }

  removeSsoLogin() {
    this.disableUpdateSsoButton = true;
    const model: RemoveSsoCommand = {};

    if (this.userSettingsQueryResult.PasswordSet) {
      this.apiService.RemoveSso(model).subscribe(res => {
        if (res.Result.Success) {
          this.userSettingsQueryResult.ExternalLogins = [];
          this.showUpdateSsoVariable = false;
        }
        this.disableUpdateSsoButton = false;
      }, error => {
        this.disableUpdateSsoButton = false;
      });
    }
    else {
      this.showNoPasswordSetWarning = true;
      this.disableUpdateSsoButton = false;
    }
  }

  isSsoEnabled() {
    if (!this.userSettingsQueryResult.ExternalLogins)
      return false;

    if (this.userSettingsQueryResult.ExternalLogins.length > 0)
      return true;

    return false;
  }

}


export interface UpdatePasswordCommand {
  OldPassword?: string; // required, value must be equal to password.
  NewPassword?: string; // required, value must be equal to confirm password.
  ConfirmPassword?: string; // required, value must be equal to password.
}

export interface UpdatePasswordResult extends ActionResult{
  PasswordLastUpdated: Date;
}

export interface UpdateEmailCommand {
  Email?: string;
  ConfirmEmail?: string;
}

export interface UpdateEmailResult extends ActionResult{
  Email?: string;
}

export interface UpdateNameCommand {
  FirstName?: string;
  LastName?: string;
}

export interface UpdateNameResult extends ActionResult {
  FirstName?: string;
  LastName?: string;
}

export interface AccountDetailPost {
  Account: string
}

export interface AccountDetailQueryResult extends ActionResult {
  Account: string;
  Username: string;
  LastPasswordUpdateDate: Date;
  FirstName: string;
  LastName: string;
  Email: string;
  EBillingEnabled: boolean
}

export interface UserSettingsQuery {

}

export interface UserSettingsQueryResult extends ActionResult {
  FirstName: string,
  LastName: string,
  Email: string,
  PendingEmail: string,
  PasswordLastUpdated: Date,
  SecurityQuestions: UserSettingsQuestionItem[],
  AvailableQuestions: string[],
  PasswordSet: boolean,
  ExternalLogins: ExternalLoginItem[]
}

export interface UserSettingsQuestionItem {
  Question: string,
  Answer: string,
  Index: number
}

export interface ExternalLoginItem {
  Provider: string
}

export interface RemoveSsoCommand {
}

export interface RemoveSsoCommandResult extends ActionResult {
}

import { Component, ViewChild, ElementRef } from '@angular/core';
import { PaymentService } from '../../payment.service';
import { TitleService } from '../../../../core/services/title.service';
import { LogService } from '../../../../core/services/log.service';

@Component({
    selector: 'app-autopayinfo',
    templateUrl: './autopayinfo.component.html',
    styleUrls: ['./autopayinfo.component.scss']
})
/** autopayinfo component*/
export class AutopayinfoComponent {

  @ViewChild('redirectPostForm', { static: true }) public redirectPostForm: ElementRef;
  @ViewChild('submitButton', { static: true }) public submitButton: ElementRef;

    /** autopayinfo ctor */
  private hasCardOnFile: boolean;
  public autoPayInfoResult: AutoPayInfoResult;
  private Token: String;
  private RedirectUrl: String;
  private disableContinueButton: boolean = false;

    constructor(private paymentService: PaymentService,
      private titleService: TitleService,
      private logService: LogService) {
      this.titleService.setTitle("Auto Pay Info");

      const autoPayInfoQuery: AutoPayInfoQuery = { CustomerId: localStorage.getItem("AccountKey") };
      this.paymentService.GetAutoPayInfo(autoPayInfoQuery).subscribe(res => {
        this.hasCardOnFile = res.Result.CardOnFile;
        this.autoPayInfoResult = res.Result;
      }, error => console.error(error));
  }

  public continueToAutoPay() {
    this.disableContinueButton = true;
    const autoPayCommandRequest: AutoPayCommandRequest = {
      CustomerId: localStorage.getItem("AccountKey")
    }

    this.logService.info("AutoPayCommand", "Start");
    this.paymentService.PostAutoPay(autoPayCommandRequest).subscribe(res => {
      this.logService.debug("AutoPayCommand", autoPayCommandRequest, res);
      this.Token = res.Result.Token;
      this.RedirectUrl = res.Result.RedirectUrl;
      $("#PayaTokenizedXml").val(this.Token.toString());
      $("#RedirectUrl").val(this.RedirectUrl.toString());
      this.redirectPostForm.nativeElement.submit();
    }, error => {
        this.logService.error("AutoPayCommand", error, autoPayCommandRequest);
    });
  }
}

export interface AutoPayInfoQuery {
  CustomerId: string
}

export interface AutoPayInfoResult {
  AccountNumber: string,
  CardOnFile: boolean
}

export interface AutoPayCommandRequest {
  CustomerId: string
}

export interface AutoPayCommandResult {
  Token: string
  ExtraData: { [key: string]: string };
  RedirectUrl: string
  EncryptedParam: string
}

<div *ngIf="(isAuthenticated | async) && !appSettingsService.settings.maintenance" class="wrapper">
  <header-cmp></header-cmp>
  <div class="sidebar">
    <sidebar-cmp></sidebar-cmp>
  </div>
  <div class="main-panel">
    <notification></notification>
    <navbar-cmp></navbar-cmp>
    <router-outlet></router-outlet>
    <div>
      <footer-cmp></footer-cmp>
    </div>
  </div>
</div>

<div *ngIf="!(isAuthenticated | async) && !appSettingsService.settings.maintenance" class="full-page section-image">
  <div class="full-page-background" style="background-image: url('/assets/img/bg/authbg.jpg');">
    <div class="container">
      <div class="content">

        <div class="col-lg-4 col-md-6 ml-auto mr-auto">
          <div class="card card-login">
            <div class="card-header ">
              <div class="card-header ">
                <img class="mx-auto d-block mb-1" style="max-height:100px;" src="/assets/img/SC_Logo.png">
                <h6 class="header text-center"></h6>
              </div>
            </div>
            <div class="card-body" style="text-align:center;">
              <p><b>
                You have been logged out.
              </b></p>
            </div>
            <div class="card-footer">
              <a class="btn btn-warning btn-block mb-3" [routerLink]='["/authentication/logout"]' [state]='{ local: true }'>
                Login
              </a>
            </div>
          </div>
          <h1></h1>

        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="appSettingsService.settings.maintenance" class="full-page section-image">
  <div class="full-page-background" style="background-image: url('/assets/img/bg/authbg.jpg');">
    <div class="container">
      <div class="content">

        <div class="col-lg-4 col-md-6 ml-auto mr-auto">
          <div class="card card-login">
            <div class="card-header ">
              <div class="card-header ">
                <img class="mx-auto d-block mb-1" style="max-height:100px;" src="/assets/img/SC_Logo.png">
                <h6 class="header text-center"></h6>
              </div>
            </div>
            <div class="card-body" style="text-align:center;">
              <p>
                <b>Smart City MyAccount Is Under Maintenance.</b>
              </p>
              <p>
                We apologize for the inconvenience and appreciate your patience. Thank you for using Smart City.
              </p>

            </div>
            <div class="card-footer">
              <p>
                If you need assistance, please visit or contact us at <a href="https://support.smartcitymetro.com/" target="_blank">Support</a>.
              </p>
            </div>
          </div>
          <h1></h1>

        </div>
      </div>
    </div>
  </div>
</div>

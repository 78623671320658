import { Component } from '@angular/core';

@Component({
    selector: 'home-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss']
})
/** index component*/
export class IndexComponent {
    /** index ctor */
    constructor() {

    }
}
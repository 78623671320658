<div class="main-content">
  <div class="row" *ngIf="!accountDetail">
    <mat-spinner color="accent" class="m-auto"></mat-spinner>
  </div>
  <div class="row" *ngIf="accountDetail">

    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title m-0 header-type-1 firstColor">{{resource.AccountInformation.Title}}</h4>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <div class="row">
            <div class="col-12">
              <p class="mt-2 mb-2 header-type-3">{{resource.AccountInformation.AccountNumber}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{ accountDetail.Account }}</span></p>
              <p class="mt-2 mb-2 header-type-3">{{resource.AccountInformation.Username}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{ accountDetail.Username }}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.BillingPreferences.Title}}</h4>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <p class="mt-2 mb-2 header-type-3">
            {{resource.BillingPreferences.Line1}}<br />
            <span *ngIf="accountDetail.EBillingEnabled">{{resource.BillingPreferences.Line2_enabled}}</span>
            <span *ngIf="!accountDetail.EBillingEnabled">{{resource.BillingPreferences.Line2_disabled}}</span><br />
          </p>
          <mat-slide-toggle (change)="toggleBilling($event)" [disabled]="disableEBillingButton" [checked]="accountDetail.EBillingEnabled" class="p-3">
            {{resource.BillingPreferences.Line3}}
          </mat-slide-toggle>
          <small *ngIf="pendingEBillingButton" class="mt-2 d-block text-danger">{{resource.BillingPreferences.PendingEBilling}}</small>
          <p *ngIf="disableEBillingOptOutPressed" class="mt-2 d-block text-danger">{{resource.BillingPreferences.EBillingOptOutDisabled}}</p>
          <small class="mt-2 d-block">{{resource.BillingPreferences.Line4}}</small>
        </div>
      </div>
    </div>

    <div class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">{{resource.Marketing.Title}}</h4>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <p class="mt-2 mb-2 header-type-3">
            {{resource.Marketing.Line1}}<br />
            <span *ngIf="!accountDetail.MarketingOptOut">{{resource.Marketing.Line2_enabled}}</span>
            <span *ngIf="accountDetail.MarketingOptOut">{{resource.Marketing.Line2_disabled}}</span><br />
          </p>
          <mat-slide-toggle (change)="toggleMarketing($event)" [checked]="!accountDetail.MarketingOptOut" class="p-3">
            {{resource.Marketing.Line3}}
          </mat-slide-toggle>
          <small class="mt-2 d-block">{{resource.Marketing.Line4}}</small>
        </div>
      </div>
    </div>
    <div *ngIf="iframeUrls.length > 0" class="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-7">
              <div class="pull-left">
                <h4 class="card-title m-0 header-type-1 firstColor">Your Broadband Plan</h4>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="pull-right">
              </div>
            </div>
          </div>
          <hr class="mb-2 mt-2" />
        </div>
        <div class="card-body pb-2 pt-0">
          <div *ngFor="let plan of iframeUrls" class="responsive-iframe mb-3">
            <p class="mt-2 mb-2 header-type-3">Account#: <span>{{ plan.member }}</span></p>
            <div style="position: relative; width: 100%; height: 0; padding-bottom: 48%; overflow: hidden; min-height: 1080px;">
              <iframe [src]="plan.iframeUrl"
                      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 0; min-width: 300px;"
                      frameborder="0"
                      scrolling="yes"
                      allowfullscreen></iframe>
            </div>
          </div> <!--End ngfor-->
        </div>
      </div>
    </div> <!--End iframe card-->
  </div>
</div>

<div class="user" *ngIf="isAuthenticated | async">
  <div class="photo">
    <img src="./assets/img/faces/ayo-ogunseinde-2.jpg" />
  </div>
  <div class="info">
    <a data-toggle="collapse" href="#collapseExample" class="collapsed">
      <span>
        {{ userName | async }}
        <b class="caret"></b>
      </span>
    </a>
    <div class="clearfix"></div>
    <div class="collapse" id="collapseExample">
      <ul class="nav">
        <li>
          <a href="javascript:void(0)">
            <span class="sidebar-mini-icon">MP</span>
            <span class="sidebar-normal">My Profile</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)">
            <span class="sidebar-mini-icon">EP</span>
            <span class="sidebar-normal">Edit Profile</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)">
            <span class="sidebar-mini-icon">S</span>
            <span class="sidebar-normal">Settings</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

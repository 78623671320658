import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthorizeGuard } from '../api-authorization/authorize.guard';
import { NgModule } from '@angular/core';
import { AccountModule } from './modules/account/account.module';
import { PaymentModule } from './modules/payment/payment.module';
import { UserModule } from './modules/user/user-module';
import { LayoutComponent } from './core/layout/layout/layout.component';
import { HomeModule } from './modules/home/home.module';
import { StatementModule } from './modules/statement/statement.module';


const app_routes: Routes = [
  { path: '',
    redirectTo: 'profile/dashboard',
    pathMatch: 'full' },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'profile', loadChildren: () => import('../app/modules/account/account.module').then(m => m.AccountModule) },
      { path: 'user', loadChildren: () => import('../app/modules/user/user-module').then(m => m.UserModule) },
      { path: 'pay', loadChildren: () => import('../app/modules/payment/payment.module').then(m => m.PaymentModule) },
      { path: 'home', loadChildren: () => import('../app/modules/home/home.module').then(m => m.HomeModule) },
      { path: 'statements', loadChildren: () => import('../app/modules/statement/statement.module').then(m => m.StatementModule) },
    ],
    canActivate: [AuthorizeGuard]
  },
  { path: '**',
    redirectTo: 'profile/dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(app_routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


